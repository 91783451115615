@import "../../core/dimentions";

.event-new-year-2022 {
  &.banner-hero {
    & > .art {
      background-image: url('/static/img/events/2021/new-year-upcoming/banner-hero-back.jpg');

      @media (min-width: $mobile) {
        width: 65vw;
      }
    }

    &:after {
      // banner-hero-guillotine
      right: 10%;
    }
  }

  &.banner-compact {

    padding-right: 200px;

    &:after {
      background: url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-1.svg") bottom right 30px / 100px no-repeat,
      url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-2.svg") top right 186px / (auto 72px) no-repeat,
      url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-3.svg") top right 366px / (auto 100px) no-repeat,
      url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-4.svg") bottom right 24px / (auto 85%) no-repeat;
    }

    @media (max-width: $mobile) {
      padding-right: 100px;
      
      & > .content {
        font-size: 18px;
        line-height: 24px;
      }

      &:after {
        background: url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-1.svg") bottom right / 100px no-repeat,
        url("/static/img/events/2021/new-year-upcoming/banner-compact-decoration-2.svg") top right 14px / (auto 72px) no-repeat;
      }
    }

  }
}
