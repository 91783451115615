@import "../core/dimentions";
@import "../core/colors";
@import "../core/fonts";

.card-checklist {

  &:hover {
    text-decoration: none;
  }

  & > .cover {
    position: relative;

    & > .preview {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & > .title {
    @include font-tele2-large();
    color: $color-tele2-text-black;
    margin-top: 10px;

    &:after {
      $size: 22px;

      content: "";
      display: inline-block;
      background: url("/static/img/components/card-checklist/download.png") no-repeat center center / cover;
      width: $size;
      height: $size;

      margin-left: 18px;
      margin-bottom: -4px;
    }
  }

  @media (max-width: $mobile) {
    margin-bottom: 36px;
  }
}