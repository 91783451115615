.fitness-page-wrap {
  .tab-content-list {
    padding: 20px 0;
    .widget-box {
      padding: 0;
    }
  }
}
.walk-block {
  color: #8F9399;
  .radio {
    padding-bottom: 0;
    label {}
  }
  .walk-flex {
    display: flex;
    justify-content: space-between;
  }
  .col {
    max-width: calc(50% - 35px);
  }
  .img-block {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.fitness-page-slider {
  background-color: #F5F5FA;
  .slide {
    position: relative;
    .slide-content-wrapper {
      padding: 0 30px 80px;
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}

.map-block {
  .map-cols {
    display: flex;
    .col {
      padding: 0;
      &:nth-child(1) {

      }
      &:nth-child(2) {
        flex-shrink: 0;
        margin-left: 40px;
        max-width: 50%;
      }
    }
  }
  .address-list {
    font-size: 18px;
    .element {
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 7px;
      color: $text-default;
    }
    .address {
      color: #979797;
    }
  }

}

@media (max-width: $screen-sm) {
  .walk-block {
    color: #8F9399;

    .radio {
      padding-bottom: 0;

      label {
      }
    }

    .walk-flex {
      flex-flow: column wrap;
      justify-content: flex-start;
    }
    .col {
      padding: 0;
      max-width: none;
      width: auto;
      &:nth-child(1) {
        order: 2;
      }
      &:nth-child(2) {
        margin-bottom: 20px;
        order: 1;
      }
    }
    .img-block {
      height: 235px;
    }
  }
  .map-block {
    .map-cols {
      display: flex;
      flex-flow: column wrap;
      .col {
        padding: 0;
        &:nth-child(1) {
          margin-bottom: 20px;
        }
        &:nth-child(2) {
          max-width: inherit;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .fitness-page-slider {
    background-color: #F5F5FA;
    .slide {
      position: relative;
      .slide-content-wrapper {
        padding: 0 0 0 15px;
        flex-flow: column wrap;
        align-items: flex-start;
      }
    }
  }
  .map-block {
    .address-list {
      font-size: 14px;
    }
  }
}
