.promo-block.new-year {
  .girls {
    background: url(/static/img/events/newyear20/slider-img.png) right center no-repeat;
  }
}

.new-year-text-block {
  position: relative;
  padding: 45px 0 50px;
  color: #626469;
  font-size: 18px;
  .img-block {
    margin-right: 22px;
    img {
      display: block;
    }
  }
}

.new-year-event-block {
  .text-block {
    padding: 30px 26px 36px 30px;
    background-color: #F5F5F5;
  }
  p {
    font-size: 22px;
  }
}

.banner-block.new-year {
  position: relative;
  padding: 0;
  overflow: hidden;
  margin-bottom: 40px;
  img {
    display: block;
  }
  .title {
    text-align: center;
    padding: 32px 0;
    margin-bottom: 0;
    color: $white;
    font-size: 47px;
    line-height: normal;
  }
  .banner {
    position: absolute;
    top: -38px;
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
}

.widget-box {
  position: relative;
  .new-year {
    position: absolute;
    &.ded1 {
      top: -9px;
      left: 270px;
    }
    &.ded2 {
      left: 50%;
      bottom: 0;
    }
    &.ded3 {
      right: 70px;
      bottom: 0;
    }
    &.ded4 {
      right: 150px;
    }
    &.ded5 {
      top: -28px;
      left: 40%;
    }
    &.ded6 {
      top: -80px;
      left: 40%;
    }
    &.ded8 {
      bottom: 0;
      left: 50%;
    }
    &.pengi {
      right: 20%;
      bottom: 0;
    }
    &.snowman {
      left: 346px;
      bottom: -8px;
    }
    &.gift2 {
      right: 35%;
      bottom: 0;
    }
  }
}
.cat-block {
  position: relative;
  .new-year {
    position: absolute;
    top: 0;
    right: 80px;
  }
}

@media (max-width: $screen-sm) {
  .widget-box {
    .new-year {
      &.ded1 {
        left: inherit;
        right: 28px;
        width: 88px;
      }
      &.ded2 {
        width: 85px;
        left: inherit;
        right: 0;
      }
      &.ded3 {
        right: 0;
        bottom: 0;
        width: 106px;
      }
      &.ded4 {
        right: 15px;
        width: 100px;
      }
      &.ded5 {
        display: none;
        left: 40%;
      }
      &.ded6 {
        top: 10px;
        left: inherit;
        right: 12px;
        width: 92px;
      }
      &.ded8 {
        left: inherit;
        right: 12px;
        width: 105px;
        bottom: 65px;
      }
      &.snowman {
        left: inherit;
        bottom: -8px;
        right: 0;
        width: 80px;
      }
      &.pengi {
        right: 13px;
        width: 50px;
      }
      &.gift2 {
        right: 14px;
        width: 90px;
        bottom: 0;
      }
    }
  }
  .cat-block {
    position: relative;
    .new-year {
      position: absolute;
      top: 78px;
      right: 9px;
      width: 65px;
      z-index: 1;
    }
  }
  .promo-block.new-year {
    .girls {
      background: url(/static/img/events/newyear20/slider-img-mobile.png) center top no-repeat;
    }
    .info-block {
      .title {
        margin-bottom: 10px;
      }
    }
  }
  .banner-block.new-year {
    padding: 0;
  }
  .new-year-text-block {
    padding: 20px 0 14px;
    font-size: 14px;
    .img-block {
      position: absolute;
      bottom: 115px;
      right: -40px;
      img {
        width: 88px;
      }
    }
  }
  .banner-block.new-year {
    margin-bottom: 30px;
    display: block;
    .title {
      margin: 0 auto;
      max-width: 55%;
      font-size: 27px;
    }
    .banner {
      &.left {
        bottom: inherit;
        top: 0;
        left: 0;
      }
      &.right {
        top: inherit;
        right: 0;
        bottom: 0;
      }
    }
  }
  .new-year-event-block {
    .img-block {
      order: 1;
    }
    .text-block {
      order: 2;
      padding: 20px 10px 23px;
    }
    p {
      font-size: 16px;
    }
  }
}
