.service-consult-wrap {
  .filter-block {
    position: absolute;
    top: 5px;
    right: 70px;
    .filter-toggle {
      padding: 9px 12px;
      cursor: pointer;
      &:hover {
        background-color: #D1DBD6;
      }
      img {
        margin-right: 3px;
        display: block;
      }
    }
    .filter-list {
      display: none;
      position: absolute;
      top: 39px;
      width: 100%;
      z-index: 1;
      background-color: $white;
      .element {
        padding: 7px 10px;
        border-bottom: 1px solid $gray;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &.active {
      .filter-toggle {
        background-color: #D1DBD6;
      }
      .filter-list {
        display: block;
      }
    }
  }
  .tab-list {
    margin-bottom: 20px;
    position: relative;
    .tab {
      margin-right: 32px;
      flex-grow: 0;
      padding: 8px 2px 18px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .top-block {
    padding: 50px 0 45px;
    background-color: $gray;
    .title {
      margin-bottom: 18px;
      font-size: 38px;
      font-weight: 700;
      color: $text-default;
    }
    .text {
      font-size: 18px;
      color: #626469;
    }
  }
  .specialist-list {
    margin-left: -35px;
    overflow-y: auto;
    .specialist-block {
      margin-left: 35px;
      width: calc(31.3% - 35px);
      flex-grow: 1;
      max-width: calc(33.3% - 35px);;
      flex-shrink: 0;
      .img-block {
        margin-bottom: 10px;
        @include aspect-ratio(16, 9, 'inner-wrap');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
      .date {
        font-size: 14px;
        color: #979797;
      }
      .title {
        font-size: 16px;
        font-weight: normal;
        color: $text-default;
      }
    }
  }
  .bot-block {
    padding: 60px 0;
    .title {
      margin-bottom: 18px;
      font-size: 38px;
      font-weight: 700;
      color: $text-default;
    }
    .text {
      font-size: 18px;
      color: $text-default;
      &.gray {
        color: #626469;
        background-color: transparent;
        margin-bottom: 30px;
      }
    }
  }
  .spec-list {
    margin-left: -15px;
    overflow-x: auto;
    .spec-block {
      margin-bottom: 25px;
      margin-left: 15px;
      position: relative;
      flex-shrink: 0;
      width: calc(20% - 15px);
      min-width: 205px;
      min-height: 418px;
      padding: 15px 16px 22px;
      border: 1px solid #D1D6DB;
      .top {
        width: 100%;
        text-align: center;
      }
      .special-event {
        position: absolute;
        top: 55px;
        right: 0;
        padding-right: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 75px;
        height: 30px;
        background: url('/static/img/spec/special-ribbon.svg') no-repeat;
        font-family: "T2 Halvar Breitschrift", sans-serif;
        font-size: 9px;
        font-weight: 700;
        color: $white;
        text-align: center;
      }
      .day-block {
        margin-bottom: 20px;
        padding: 0 10px;
        height: 25px;
        line-height: 24px;
        color: $text-default;
        background-color: #DADADA;
      }
      .avatar-block {
        margin: 0 25px 25px;
        overflow: hidden;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include aspect-ratio(1,1, 'inner-wrap');
      }
      .name {
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 700;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
      }
      .prof {
        margin-bottom: 15px;
        font-size: 15px;
        color: #8F9399;
      }
      .btn {
        display: none;
        padding: 0 12px;
        max-width: 175px;
        height: 50px;
        img {
          margin-right: 2px;
          width: 35px;
        }
      }
      &.active,
      &:hover {
        .day-block {
          color: $white;
          background-color: $text-default;
        }
        .btn {
          display: block;
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .service-consult-wrap {
    .filter-block {
      right: inherit;
      left: 10px;
      top: 48px;
      z-index: 1;
    }
    .top-block {
      padding: 30px 0 10px;
      .title {
        margin-bottom: 20px;
        font-size: 32px;
        font-weight: 700;
        color: $text-default;
      }
      .text {
        font-size: 14px;
        color: #626469;
      }
    }
    .tab-list {
      margin-bottom: 42px;
      .tab {
        padding-bottom: 8px;
      }
    }
    .specialist-list {
      margin-left: 0;
      .specialist-block {
        margin-left: 0;
        width: 100%;
        max-width: initial;
      }
    }
    .bot-block {
      padding: 30px 0 70px;
      .text {
        font-size: 14px;
      }
      .spec-list {
        margin: 0 0 5px 0;
        .spec-block {
          margin: 0 0 10px 0;
          padding: 35px 10px 20px;
          width: 100%;
          min-height: auto;
        }
        .prof {
          margin-bottom: 0;
        }
        .special-event {
          top: -35px;
          right: -10px;
          z-index: 1;
        }
        .day-block {
          position: absolute;
          top: -35px;
          left: -10px;
          font-size: 13px;
        }
        .dot {
          position: absolute;
          top: -28px;
          right: -3px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $blue;
        }
        .top {
          margin-bottom: 16px;
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .avatar-block {
            margin: 0 0 0 15px;
            flex-shrink: 0;
            order: 2;
            width: 85px;
            height: 85px;
          }
          .info-block {
            text-align: left;
            order: 1;
            flex-grow: 1;
          }
        }
      }
    }
  }
}
