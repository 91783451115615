@import "../core/dimentions";
@import "../core/colors";
@import "../core/fonts";
@import "../core/ui-kit/buttons";

.bootstrap-modal {

  & > .modal-dialog {

    & > .modal-content {
      border-radius: 0;

      @media (min-width: $mobile) {
        border: 1px solid $color-tele2-background-black;
      }

      & > .modal-header {
        padding: 0;
        border-bottom: none;

        margin: 16px 18px 36px;

        @media (min-width: $screen-sm) {
          margin: 36px;
        }

        & > .modal-title {
          @include font-tele2-sub-title();

          font-size: 28px;
          line-height: 36px;

          @media (max-width: $screen-sm) {
            font-size: 24px;
            line-height: 28px;
          }
        }

        & > button.close {
          //content: url(@image-path);
          $size: 24px;

          font-size: 0;
          color: transparent;
          background-image: url("/static/img/components/modal/close.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: $size $size;
          width: $size;
          height: $size;

          padding: (20px - 16px) (22px - 18px);
          margin: 0;
          opacity: 1;

          @media (min-width: $screen-sm) {
            padding: (40px - 36px);
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }

      & > .modal-body {
        margin: 0 18px;
        padding: 0;

        &.compact {
          flex-grow: 0;
        }
      }

      & > .modal-footer {
        border-top: none;
        padding: 0 0 18px;

        justify-content: flex-start;
        flex-direction: column;

        & > .btn {

          &.btn-primary {
            @include button-dark();
          }

          @include button-clear-dark();
        }
      }
    }
  }

  @media (min-width: $screen-sm) {

    & > .modal-dialog {

      max-width: 732px;

      display: block;
      margin: 40px auto 0;

      & > .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;

        & > .modal-body {
          margin: 0 36px;
        }

        & > .modal-footer {
          margin: 0 36px 38px 36px;
          flex-direction: row;
        }
      }
    }
  }
}
