.modal-dialog {
  max-width: inherit;
  .modal-content {
    border: none;
    padding: 24px;
    border-radius: 32px;
  }
  @media(max-width: $screen-sm) {
    margin: 0 auto;
    background-color: $gray3;
    .modal-content {
      height: 100vh;
      overflow-y: auto;
      padding: 0;

      &.small {
        height: auto;
      }
    }
  }
}

.modal-form {
  max-width: 600px;
}

.habit-form {
  position: relative;
  padding: 40px 50px;
  max-width: 738px;
  width: 100vw;
  .close {
    position: absolute;
    top: 44px;
    right: 40px;
    width: 32px;
    height: 32px;
    background: url('/static/img/svg/close-black.svg') center no-repeat;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .left-block {
    margin-right: 50px;
    flex-shrink: 0;
  }
  .title {
    margin-bottom: 20px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: $text-default;
  }
  .text-block {
    margin-bottom: 20px;
    font-size: 18px;
    color: #626469;
  }
  .btn {
    margin-bottom: 16px;
  }
  img {
    display: block;
  }
  .info {
    font-size: 14px;
    color: #626469;
  }
  @media (max-width: $screen-sm) {
    padding: 18px 16px;
    .close {
      top: 23px;
      right: 18px;
    }
    .title {
      margin: 0 35px 18px 0;
      font-size: 24px;
    }
    .text-block {
      margin-bottom: 30px;
      font-size: 14px;
    }
    .img-block {
      margin-right: 10px;
      img {
        width: 85px;
      }
    }
  }
}

.modal-full {
  max-width: 1136px;
  width: 100vw;
  @media (max-width: $screen-sm) {
    margin: 0;
    height: 100vh;
    border: none;
  }
  &.medium {
    max-width: 535px;
  }
}

.navigator-wrap {
  position: relative;
  //padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  background-color: $gray3;
  .main-header {
    //margin-bottom: 28px;
    .icon {
      margin: 4px 10px 0 0;
      font-size: 14px;
    }
  }
  .head-block {
    margin: -40px -40px 18px;
    position: relative;
    padding: 14px 40px;
    background: #F5F5FF;
    .close {
      top: 14px;
      right: 32px;
    }
    .title {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-size: 20px;
      color: $text-default;
      font-weight: bold;
    }
    .icon {
      margin: 4px 10px 0 0;
      flex-shrink: 0;
      font-size: 14px;
    }
    .icon-block {
      margin-right: 12px;
      width: 32px;
      height: 32px;
      font-size: 18px;
      color: $white;
      background-color: $text-default;
      border-radius: 50%;
    }
  }
  .close {
    position: absolute;
    //top: 35px;
    right: 35px;
    width: 24px;
    height: 24px;
    background: url('/static/img/svg/close-black.svg') center no-repeat;
    //opacity: 1;
    cursor: pointer;
    background-size: contain;
    &:hover {
      opacity: 1;
    }
  }
  .img-link-list {
    margin-left: -10px;
  }
  a {
    margin-bottom: 16px;
    font-size: 16px;
    color: black;
  }
  .img-link {
    margin: 0 0 24px 10px;
    width: calc(33.3% - 10px);
    .img-block {
      margin-right: 8px;
      flex-shrink: 0;
      max-width: 102px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .link-back {
    display: block;
    text-decoration: none;
    .icon {
      font-size: 24px;
      padding-right: 10px;
    }
  }
  .link-main {
    display: block;
    text-decoration: none;
    padding: 24px;
    border-radius: 32px;
    background-color: white;
  }
  .content-wrap {
    overflow-y: auto;
  }
  @media (max-width: $screen-sm) {
    height: 100%;
    border: none;
    padding: 12px;
    gap: 8px;
    .content-wrap {
      padding: 6px 0;
    }
    .close {
      right: 15px;
    }
    .link-back {
      padding: 0 12px;
    }
    .link-main {
      padding: 8px;
      border-radius: 16px;
    }
    .main-header {
      padding: 6px;
      margin-bottom: 0;
    }
    .head-block {
      margin-bottom: 5px;
    }
    a {
      margin-bottom: 8px;
      padding: 0px;
    }
    .img-link {
      margin-bottom: 0;
      width: 100%;
      padding: 15px 20px;
    }
  }
}

.header-mobile-wrap {
  .head-block {
    position: relative;
    padding: 18px 16px;
    font-size: 14px;
    color:  #626469;
    background-color: #F5F5FF;
    .user-block {
      margin-right: 10px;
      flex-shrink: 0;
      width: 23px;
      height: 23px;
      background: url('/static/img/svg/user.svg') center / cover no-repeat;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 16px;
      width: 30px;
      height: 30px;
      background: url('/static/img/svg/close-black.svg') center / contain no-repeat;
      opacity: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .search-block {
    position: relative;
    margin: 18px 16px 0;
    .search {
      position: absolute;
      top: 10px;
      left: 12px;
      font-size: 20px;
      color: #8F9399;
    }
    input {
      width: 100%;
      padding: 9px 16px 9px 40px;
      border: 1px solid #D4D9DE;
      border-radius: 8px;
    }
  }
  .menu-list {
    a {
      padding: 14px 16px;
      font-size: 16px;
      color: #63656A;
      border-bottom: 1px solid #E8E8ED;
      &:last-child {
        border-bottom: none;
      }
    }
    .icon {
      font-size: 12px;
      color: $text-default;
    }
  }
  @media (max-width: $screen-sm) {
    height: 100%;
    border: none;
  }
}

.form-wrap-default {
  position: relative;
  .title-main {
    margin-bottom: 18px;
    font-size: 30px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: 700;
    color: $text-default;
  }
  .close {
    position: absolute;
    top: 35px;
    right: 35px;
    font-size: 32px;
    cursor: pointer;
  }
  p {
    color: #626469;
  }
}

.form-stop-weight {
  max-width: 925px;
  .info-block {
    padding: 45px 32px 32px;
  }
  .close {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 32px;
    height: 32px;
    background: url('/static/img/svg/close-black.svg') center no-repeat;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .btn {
    padding: 11px 34px;
  }
  @media (max-width: $screen-sm) {
    .close {
      top: 18px;
      right: 27px;
    }
    img {
      width: 100%;
    }
    .info-block {
      padding: 16px 16px 32px;
    }
  }
}

.add-notes-wrap {
  padding: 36px 36px 40px;
  .title {
    margin-bottom: 28px;
    font-size: 28px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: bold;
  }
  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 32px;
    height: 32px;
    background: url('/static/img/svg/close-black.svg') center / contain no-repeat;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .btn {
    padding: 11px 35px;
  }
}

.shape-form {
  margin: 0 auto;
  padding: 30px 40px;
  max-width: 732px;
  .title {
    margin-bottom: 40px;
    font-size: 28px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: bold;
  }
  .close {
    position: absolute;
    top: 34px;
    right: 44px;
    width: 24px;
    height: 24px;
    background: url('/static/img/svg/close-black.svg') center / contain no-repeat;
    opacity: 1;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .text {
    margin-bottom: 26px;
  }
  .btn {
    width: auto;
  }
  .btn-block {
    .btn {
      min-width: 110px;
    }
    .btn:first-child {
      margin-right: 32px;
    }
  }
  @media (max-width: $screen-sm) {
    padding: 16px 18px;
    .close {
      top: 20px;
      right: 22px;
      width: 24px;
      height: 24px;
    }
    .title {
      margin-bottom: 44px;
      font-size: 24px;
      line-height: 28px;
    }
    .text {
      margin-bottom: 26px;
      line-height: 24px;
    }
    .btn-block {
      .btn:first-child {
        margin: 0 0 18px;
      }
    }
  }
}
