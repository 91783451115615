.health-marathon {
  .motivate-strip {
    padding: 4px 15px;
    font-size: 14px;
    color: $white;
    background-color: $text-default;
    .icon {
      margin-right: 6px;
      flex-shrink: 0;
      width: 21px;
      height: 21px;
      background: url('/static/img/health/social-network.svg') no-repeat;
    }
  }
  .stat-wrap {
    margin-bottom: 20px;
    padding: 10px 15px 20px;
    background-color: #F5F5FA;
    .canceled {
      font-size: 11px;
      color: #626469;
    }
    .start-block {
      margin-bottom: 18px;
      padding-top: 15px;
      .top {
        margin-bottom: 22px;
      }
      .avatar {
        margin-left: 14px;
        flex-shrink: 0;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        background: url('/static/img/health/cat.svg') center / 65% $white no-repeat;
      }
      b {
        color: $black;
      }
      .text {

      }
    }
    .title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 18px;
    }
    .marathon-days {
      padding: 8px;
      background-color: $text-default;
      border-radius: 4px;
      .count {
        margin-right: 6px;
        font-family: 'Standard Stencil Bold', sans-serif;
        font-size: 44px;
        line-height: 38px;
        color: $white;
      }
      .info {
        height: 38px;
        font-size: 14px;
        line-height: 14px;
        color: #dadada;
      }
    }
    .visitors-wrap {
      padding: 4px;
      background-color: $text-default;
      border-radius: 4px;
      .digit {
        margin-right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4F4F4C;
        font-size: 35px;
        font-weight: bold;
        width: 25px;
        height: 46px;
        background: url('/static/img/health/counter-bg.svg') center / 100% 100% no-repeat;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .collapse-block {
    margin-bottom: 20px;
    font-size: 14px;
    color: #626469;
    .content {
      margin-bottom: 10px;
      height: 104px;
      overflow: hidden;
    }
    .read-more {
      font-size: 16px;
      color: $text-default;
      .icon {
        margin-top: 3px;
      }
      text-decoration: none;
    }
    .text {
      display: block;
      &.hide {
        display: none;
      }
    }
    &.open {
      .content {
        height: auto;
      }
      .text {
        display: none;
        &.hide {
          display: block;
        }
      }
    }
  }
  .decline-block {
    margin-bottom: 30px;
    height: 180px;
    .img-block {
      flex-grow: 1;
      background: url('/static/img/health-mobile2.png') #F5F5FA center bottom / auto 90% no-repeat;
    }
    .info-block {
      padding: 15px 8px;
      flex-shrink: 0;
      color: $white;
      max-width: 120px;
      background-color: $text-default;
      .title {
        margin-bottom: 12px;
        font-family: 'Standard Stencil Bold', sans-serif;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
      }
      a {
        color: $white;
        text-decoration: none;
      }
      .participate {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .widget-desc {
    margin-bottom: 28px;
    color: #626469;
  }
  .daily-wrap {
    padding-top: 30px;
    margin-bottom: 30px;
    background-color: $white;
    .title {
      margin-bottom: 18px;
      font-size: 28px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: 700;
      color: $text-default;
    }
    .result-block {
      margin-bottom: 20px;
      padding: 15px 20px 7px 8px;
      background: $white;
      box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
    }
    .top-block {
      margin-bottom: 18px;
    }
    .icon {
      margin-right: 6px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      width: 37px;
      height: 37px;
      color: $white;
      border-radius: 50%;
      background-color: $text-default;
      &.icon-cigar {
        &:before {
          margin: -4px 0 0 -3px;
          font-size: 22px;
        }
      }
    }
    .info-block {
      margin-bottom: 15px;
      font-size: 12px;
      .text {
        color: #626469;
      }
    }
    b {
      font-size: 14px;
      color: $black;
    }
    .bot-block {
      b {
        display: block;
        margin-bottom: 12px;
      }
    }
    .bot-list {
      .bot {
        margin-bottom: 10px;
      }
      .icon {
        margin-right: 5px;
      }
      a {
        margin: 0 20px;
      }
    }
    .all-link {
      font-size: 13px;
      color: $black;
      @include link-line($black);
    }
  }
  .result-wrap {
    padding: 30px 0 30px;
    background-color: $gray;
    .title {
      margin-bottom: 18px;
      font-size: 28px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: 700;
      color: $text-default;
    }
    .result-list {
      margin-bottom: 10px;
    }

    .all-link {
      font-size: 16px;
      color: $black;
      @include link-line($black);
    }
  }
  .result-block {
    margin-bottom: 10px;
    padding: 14px;
    background-color: $white;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
    .top-block {
      margin-bottom: 10px;
      .icon {
        margin-right: 6px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        width: 32px;
        height: 32px;
        color: $white;
        border-radius: 50%;
        background-color: $text-default;
        &.icon-cigar {
          &:before {
            margin: -4px 0 0 -3px;
            font-size: 22px;
          }
        }
      }
      .text {
        font-size: 14px;
      }
    }
    .list {
      margin-left: -7px;
      display: flex;
      flex-flow: row wrap;
      .icon {
        margin: 0 0 7px 7px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(31, 34, 41, 0.2);
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: $gray;
        &.now {
          color: $text-default;
        }
        &.done {
          color: #41A674;
          background: rgba(65, 166, 116, 0.3);
        }
        &.undone {
          color: #CC2128;
          background-color: rgba(204, 33, 40, 0.3);
        }
      }
    }
  }
  .cancel-block {
    padding: 9px 15px 12px;
    font-size: 16px;
    background-color: $black;
    a {
      color: $white;
      @include link-line($white);
    }
  }
  .promo-info-wrap {
    .top-block {
      padding: 45px 0 20px;
      .img-block {
        margin-right: 24px;
        flex-shrink: 0;
      }
      .text-block {
        font-size: 18px;
      }
    }
    .habits-wrap {
      margin-bottom: 20px;
      padding: 30px 26px 34px;
      background-color: $gray;
      .title {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: bold;
      }
      .text {
        color: #626469;
      }
    }
    .habit-block {
      margin-right: 55px;
      flex-shrink: 0;
      padding: 0;
      width: 75px;
      background-color: transparent;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
      .icon-block {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 35px;
        width: 100%;
        height: 75px;
        background-color: $text-default;
        border-radius: 50%;
        .icon {
          margin-right: 0;
          width: auto;
          height: auto;
          &.icon-cigar {
            &:before {
              margin: -5px 0 0 -7px;
              font-size: 41px;
            }
          }
        }
      }
      .title {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .join-block {
      margin-bottom: 20px;
      .text-block {
        padding: 40px 30px;
        background-color: $gray;
        font-size: 22px;
        color: #626469;
      }
      .img-block {
        flex-shrink: 0;
        max-width: 348px;
        width: 100%;
        height: 232px;
        background-size: cover;
      }
    }
    .result-wrap {
      padding: 0;
      background-color: transparent;
      .result-block {
        width: calc(33.3% - 18px);
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .health-marathon {
    .promo-info-wrap {
      .top-block {
        padding: 20px 0 18px;
        .text-block {
          font-size: 14px;
        }
      }
      .habits-wrap {
        margin: 0 -15px 10px;
        padding: 20px 15px 26px;
        background-color: $gray;
        .title {
          margin-bottom: 23px;
          font-size: 16px;
          font-weight: bold;
        }
        .text {
          font-size: 14px;
        }
      }
      .habits-list {
        margin: 0 0 15px -16px;
      }
      .habit-block {
        margin: 0 0 15px 16px;
        max-width: 60px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        .icon-block {
          width: 50px;
          height: 50px;
          font-size: 25px;
        }
        .title {
          margin-bottom: 0;
          font-size: 13px;
        }
        .text {
          font-size: 13px;
        }
      }
      .join-block {
        .img-block {
          width: 100%;
          height: 120px;
          order: 1;
        }
        .text-block {
          padding: 20px 10px 23px;
          order: 2;
          font-size: 16px;
        }
      }
      .result-block {
        width: 100%;
      }
      .result-wrap {
        padding: 0;
        background-color: transparent;

      }
    }
  }
}
