@import "../variables";

$dim-card-width: 348px;
$dim-card-height: 200px;

$dim-page-padding-mobile: 16px;
$dim-basic: 18px;
$dim-grid-distance: 36px;

$mobile: $screen-sm;
