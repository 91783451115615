* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'T2 Rooftop', sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

header {
  flex: 0 0 auto;
}

.content {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

.container-fluid {
  max-width: 1136px;
  padding: 0px;
  //padding: 32px 0px;
  //gap: 32px;
}

.rouble {
  font-family: 'ALSRublRegular', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dark-bg {
  background-color: $bgDark;
}

.btn,input[type='button'] {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 16px 24px;
  width: auto;
  color: $text-default;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'T2 Halvar Breitschrift';
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  outline: none !important;
  cursor: pointer;
  user-select: none;
  border: 1px solid $black;
  background-color: transparent;
  border-radius: 8px;
  height: 52px;
  align-content: center;
  &:hover {
    color: rgba(31,34,41,.7);
    border-color: rgba(31,34,41,.7);
  }
  &:focus {
    box-shadow: none;
  }
  &.small {
    border-radius: 8px;
    height: 32px;
    padding: 8px 12px;
    line-height: 16px;
  }
  &.btn-dark {
    color: $white;
    border-color: $text-default;
    background-color: $black;
    &:hover {
      background-color: $header-link;
      border-color: $header-link;
    }
  }
  &.btn-white{
    color: $black;
    border-color: $white;
    background-color: $white;
  }
  &.btn-green{
    color: $black;
    border-color: $green;
    background-color: $green;
  }
  &.btn-blue{
    color: white;
    border-color: $blue3;
    background-color: $blue3;
  }
  &.btn-blue2{
    color: black;
    border-color: $blue2;
    background-color: $blue2;
  }
  &.btn-pink{
    color: white;
    border-color: $pink;
    background-color: $pink;
  }
  &.btn-invert {
    color: $white;
    border: 2px solid $white;
    &:hover {
      color: #8f9399;
      border-color: #8f9399;
    }
  }
  &.btn-gray{
    color: black;
    border-color: $gray5;
    background-color: $gray5;
  }
  tab-content-list
  &.wide {
    display: block;
    text-align: center;
  }
}

.gray {
  background-color: $gray;
}
.color-red {
  color: $errorText2 !important;
}
.color-default {
  color: $text-default;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.widget-block {
  border-radius: 32px;
  padding: 24px;
  cursor: pointer;
  .img {
    width: 24px;
    height: 24px;
  }
}
.title-top {
  font-size: 38px;
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-weight: 800;
  line-height: 34px;
  text-transform: uppercase;
}
.title {
  font-size: 24px;
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-weight: 800;
  line-height: 22px;
  text-transform: uppercase;
}
.sub-title {
  font-size: 16px;
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-weight: 800;
  line-height: 22px;
  text-transform: uppercase;
}
.text {
  font-size: 15px;
  line-height: 22px;
}
.text-small {
  font-size: 12px;
  line-height: 16px;
}
.all-cover-link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-services {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.banner {
  border-radius: 32px;
  height: 152px;
  padding: 8px;
  .info-block {
    padding: 24px;
    max-width: 520px;
    border-radius: 24px;
    .text {
      max-width: 440px;
    }
  }
  &.eye {
    background: url('/static/img/banners/woman-eye.png') right center #D9D9D9 no-repeat;
    .info-block {
      background-color: $green;
    }
  }
  &.podcast {
    background: url('/static/img/banners/podcast.jpg') 10% #D9D9D9 no-repeat;
    color: white;
    .info-block {
      background-color: $pink;
    }
  }
  &.spec {
    height: 318px;
    background: url('/static/img/banners/doc.jpg') right center #D9D9D9 no-repeat;
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    .info-block {
      background-color: $pink;
    }
  }
  &.smok {
    height: 208px;
    padding: 12px;
    background: url('/static/img/banners/smok.png') right center #4D5156 no-repeat;
    color: white;
    .info-block {
      background-color: black;
      height: 100%;
    }
  }
  &.wate {
    height: 208px;
    padding: 12px;
    background: url('/static/img/banners/wate.jpg') 150% white no-repeat;
    color: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
    .info-block {
      background-color: $blue2;
      height: 100%;
    }
  }
  &.timer {
    height: 208px;
    padding: 12px;
    background: url('/static/img/banners/timer.png') right center #ECEBEC no-repeat;
    color: black;
    .info-block {
      background-color: $gray5;
      height: 100%;
    }
  }
}
.double-banner {
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 32px;
  //padding: 24px;
  .info-block {
    border-radius: 24px;
    background-color: $gray3;
    padding: 24px;
    flex-grow: 1;
    .title {
      font-size: 24px;
      font-weight: 800;
      line-height: 22px;
    }
    .btn {
      max-width: 395px;
    }
  }
  .img-block {
    background: url('/static/img/banners/woman.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 462px;
    border-radius: 24px;
  }
}

.content-wrap {
  background-color: #fff;
  border-radius: 32px;
  padding: 32px;
}
.container-wrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.widget-form-block {
  position: relative;
  padding: 30px 0 25px;
  color: $white;
  background-color: $text-default;
  z-index: 1;
  .title-block {
    margin-bottom: 20px;
  }
  .text {
    font-size: 18px;
    line-height: 25px;
  }
  .custom-select-block,
  .custom-input-block {
    margin-bottom: 25px;
    width: 35%;
    min-width: 300px;
  }
  .btn-block {
    margin-bottom: 25px;
    width: auto;
  }
  .btn {
    min-width: 185px;
    text-decoration: none;
  }
  .time-wrap {
    margin-bottom: 30px;
  }
  .time-setter-block {
    margin-bottom: 4px;
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid $header-link;
    .element {
      padding-right: 15px;
      flex-grow: 1;
      &.time {
        width: 350px;
      }
      &.days {
        width: 410px;
      }
      &.off {
        width: 90px;
      }
      &.del {
        width: 75px;
      }
    }
    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .add-more {
    display: flex;
    align-items: center;
    text-decoration: none;
    &:before {
      margin-right: 10px;
      content:'';
      display: block;
      width: 20px;
      height: 20px;
      background: url(../img/svg/close-white.svg) 50% / cover no-repeat;
      transform: rotate(-45deg);
    }
    color: $white;
  }
  .sep {
    margin: 20px 0;
    border: 1px solid #626569;
  }
  .stat {
    font-size: 14px;
    flex-grow: 1;
    margin-right: 10px;
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &.white {
    color: $text-default;
    background-color: $white;
  }
}

.custom-select-block {
  position: relative;
  >label {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 13px;
    color: #8F9399;
  }
  .select-input-block {
    position: relative;
    padding: 19px 15px 7px 0;
    color: $white;
    font-size: 16px;
    border-bottom: 2px solid $white;
    cursor: pointer;
    &:after {
      position: absolute;
      right: 12px;
      bottom: 10px;
      content: "\e906";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
  }
  .select-list {
    display: none;
    position: absolute;
    margin-top: -2px;
    width: 100%;
    color: $text-default;
    background-color: $white;
    box-shadow: 0 0 2px 0;
    .element {
      padding: 7px 0 7px 15px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: darken($white, 20%);
      }
    }
    &.checklist {
      padding: 8px 4px;
      box-shadow: 0 50px 30px -40px rgba(31, 34, 41, 0.02), 0px 2px 8px rgba(31, 34, 41, 0.04), 0px 17px 40px rgba(31, 34, 41, 0.07);
      border-radius: 8px;
      .checkbox {
        padding: 8px 12px;
        border-radius: 8px;
        label {
          margin-bottom: 0;
        }
        &:hover {
          background: #EBEDEF;
        }
      }
    }
  }
  &.active {
    z-index: 2;
    .select-list {
      display: block;
    }
  }
  &.selected {
    >label {
      display: block;
    }
  }
  &.white {
    .select-input-block {
      color: $text-default;
      border-color: $text-default;
      &:after {
        color: $text-default;
      }
    }
  }
  &.new {
    border: unset;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: $white;
    >label {
      top: 19px;
      left: 18px;
      display: block;
      font-size: 16px;
    }
    .select-input-block {
      min-height: 60px;
      padding: 24px 15px 10px;
      border-bottom: none;
      color: #798795;
      &:after {
        bottom: 14px;
        color: #798795;
        font-size: 24px;
      }
    }
    &.selected {
      >label {
        top: 6px;
        left: 15px;
        font-size: 13px;
      }
    }
  }
}

.custom-input-block {
  position: relative;
  label {
    margin-bottom: 0 !important;
    position: absolute;
    top: 20px;
    left: 16px;
    font-size: 12px;
    color: #65707B;
  }
  input, textarea {
    padding: 29px 55px 9px 16px;
    width: 100%;
    color: $text-default;
    font-size: 16px;
    line-height: normal;
    border: 1px solid #D4D9DE;
    cursor: pointer;
    outline: none;
    background-color: $white;
    border-radius: 8px;
    @include placeholder(#65707B);
    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px rgba(63, 203, 255, 0.2);
    }
  }
  .suggest-block {
    display: none;
    &.active {
      display: block;
    }
  }
  .suggest-list {
    margin-bottom: 12px;
    width: 100%;
    background-color: $white;
    border: 2px solid $text-default;
    z-index: 3;
    .suggest {
      padding: 12px 17px;
      cursor: pointer;
      &.active {
        background-color: rgba(148, 148, 148, 0.2);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.check {
    &:after {
      position: absolute;
      right: 12px;
      bottom: 20px;
      content: "\e906";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
  }
  &.disabled {
    pointer-events: none;
    input {
      color: #A2ABB4;
      background-color: #F9FAFB;
    }
  }
  &.success {
    &:after {
      content: '';
      position: absolute;
      top: 19px;
      right: 17px;
      width: 24px;
      height: 24px;
      background: url('../img/svg/iconsValid24.svg') center no-repeat;
    }
    input {
      border-color: #00802F !important;
      box-shadow: 0 0 0 4px rgba(113, 204, 128, 0.25);
    }
  }
  .error &,
  &.error {
    &:after {
      content: '';
      position: absolute;
      top: 19px;
      right: 17px;
      width: 24px;
      height: 24px;
      background: url('../img/svg/iconsError24.svg') center no-repeat;
    }
    input {
      border-color: $errorText !important;
      box-shadow: 0 0 0 4px rgba(255, 157, 174, 0.25);
    }
  }
  &.active {
    label {
      top: 9px;
      font-size: 13px;
      color: #65707B;
    }
  }
}

// Checkbox, Radiobutton
.checkbox, .radio {
  position: relative;
  //padding-bottom: 20px;
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }
  label {
    position: relative;
    display: block;
    padding: 1px 0 0 33px;
    min-height: 24px;
    line-height: 1.375em;
    color: $text-default;
    cursor: pointer;
  }
  input[type="checkbox"], input[type="radio"] {
    & + label {
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: transparent;
        border-radius: 4px;
      }
      &::before {
        border: 2px solid $gray2;
      }
      &::after {
        display: none;
      }
      &:hover:before {
        border-color: $black;
      }
    }
    &:checked {
      & + label {
        &:after {
          display: block;
        }
      }
    }
  }
  input[type="checkbox"] {
    & + label {
      &::after {
        width: 24px;
        height: 24px;
        background: url(../img/svg/checkmark.svg) no-repeat 50% 50%;
      }
    }
    &:checked {
      & + label {
        &:after {
          display: block;
          border-color: $text-default;
          background-color: $text-default;
        }
      }
    }
  }
  input[type="radio"] {
    & + label {
      &::before, &::after {
        border-radius: 50%;
      }
      &::after {
        display: none;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 50%;
      }
    }
    &:checked {
      & + label {
        &:before {
          display: block;
          border-color: $text-default;
          background-color: $text-default;
        }
      }
    }
  }
  &.disabled {
    input[type="checkbox"], input[type="radio"] {
      & + label {
        &::before {
          background-color: $gray;
          border-color: $gray;
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

  }
}

.text-field {
  padding: 20px 0 6px;
  border: none;
  color: $text-default;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 2px solid $gray2;
  background-color: transparent;
  outline: none;
  @include placeholder($gray2);
}

.widget-box {
  .widget-title-block {
    margin-bottom: 35px;
    .widget-title {
      margin-bottom: 0;
    }
    .details-block {
      div:nth-child(2) {
        margin: 0 20px;
      }
    }
  }
  .widget-title {
    margin-bottom: 35px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: bold;
    color: $text-default;
    font-size: 38px;
    &.small {
      margin-bottom: 25px;
      font-size: 24px;
    }
  }
  .widget-desc {
    margin-bottom: 35px;
    font-size: 18px;
    color: #626469;
  }
  .all-link {
    font-size: 16px;
    line-height: 22px;
    color: $black;
    @include link-line($black);
  }
  .abs-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .content-list {
    padding-bottom: 40px;
    margin: 0 0 0 -36px;
    display: flex;
    &.wrap-big {
      flex-flow: row wrap;
      .element {
        min-width: inherit;
        margin-bottom: 35px;
      }
    }
    &.wrap-all {
      flex-flow: row wrap;
      .element {
        min-width: inherit;
        margin-bottom: 35px;
      }
    }
    .element {
      position: relative;
      flex-shrink: 0;
      width: calc(33.3% - 36px);
      min-width: 211px;
      margin-left: 36px;
      .img-wrapper {
        margin-bottom: 10px;
        @include aspect-ratio(16, 9, 'img-block')
      }
      .img-block {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .social-block {
        padding-top: 3px;
        font-size: 13px;
        color: $gray2;
        .status {
          margin-right: 15px;

        }
        .partner {
          background: #F5F5F5;
          border-radius: 9.5px;
          .icon {
            margin-right: 5px;
            font-size: 19px;
            color: #D8D8D8;
          }
        }
      }
      .like-block {
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
          color: $blue;
        }
        .count {
          margin-left: 5px;
          font-size: 13px;
          line-height: 22px;
        }
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .title {
      margin-bottom: 4px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: $text-default;
    }
    .rating-block {
      font-size: 13px;
      color: #7A7A7A;
    }
    .count-block {
      font-size: 13px;
      line-height: 22px;
    }
    .cover-link {
      @include all-cover-link();
    }
    &.calculator {
      .element {
        .img-block {
          background-color: $gray;
          background-size: contain;
        }
      }
    }
    &.videos {
      .element {
        .img-wrapper {
          margin-bottom: 10px;
          @include aspect-ratio(13, 9, 'img-block')
        }
      }
    }
    &.square-small {
      .element {
        max-width: calc(25% - 25px);
        width: calc(60% - 25px);
        min-width: inherit;
        .img-wrapper {
          margin-bottom: 10px;
          @include aspect-ratio(1, 1, 'img-block')
        }
      }
    }
  }
  .category-img {
    img {
      display: block;
      max-width: 100%;
    }
  }
  .social-list {
    .social-block {
      margin-right: 30px;
      padding: 1px 15px 1px 1px;
      height: 24px;
      color: #858585;
      font-size: 14px;
      background-color: $white;
      border-radius: 12px;
      [class^="icon-"] {
        margin-right: 10px;
        font-size: 22px;
        flex-shrink: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.quiz {
    //padding: 60px 0;
    color: $text-default;
    .widget-title-block {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 38px;
        font-weight: bold;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
      }
      .category {
        font-size: 24px;
        color: $gray2;
      }
    }
    .title-question {
      margin-bottom: 25px;
      font-size: 24px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: 700;
    }
    .desc {
      margin-bottom: 30px;
      font-size: 24px;
      color: $gray2;
    }
    .quiz-block {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      .all-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        text-decoration: none;
        &:after {
          display: none;
        }
      }
      &.v2 {
        .element {
          border-radius: 16px;
          padding: 8px;
          .ansver {
            padding: 8px;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 800;
            background-color: white;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
    .element {
      width: calc(50% - 8px);
      border-radius: 16px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 190px;
      .img-wrapper {
        margin-bottom: 25px;
        @include aspect-ratio(16, 9, 'img-block');
      }
      .img-block {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 100%;
        }
      }
      .title {
        font-size: 24px;
      }
      &.active {
        .img-wrapper {
          border: 2px solid;
          &:after {
            content: '';
            position: absolute;
            top: -20px;
            right: -20px;
            background: url('../img/svg/checkmark.svg') center no-repeat black;
            background-size: contain;
            width: 50px;
            height: 50px;
            border-radius: 40px;
            border: 10px solid black;
          }
        }
      }
    }


    .quiz-question-block {
      .title {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: bold;
      }
      .quiz-block {
        cursor: default;
        .element {
          cursor: pointer;
          .title {
            font-weight: normal;
          }
        }
      }
    }
    .quiz-page-result-block {
      .table {
        margin-bottom: 0;
        th {
          vertical-align: top;
          //font-family: 'Tele2 Text Sans', sans-serif;
          &:first-child {
            padding-left: 0;
          }
        }
        tr {
          //&:last-child {
          //  border-bottom: 1px solid #D1DBD6;
          //}
        }
      }
      .text {
        margin-right: 10px;
        font-size: 14px;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        &.success {
          color: #41A674;
          background: rgba(65, 166, 116, 0.3);
        }
        &.error {
          color: $errorText;
          background-color: rgba($errorText, 0.3);
        }
      }
      .error {
        color: $errorText2;
      }
      .table {
        td {
          font-size: 16px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
  &.calc-default {
    .widget-title-block {
      .desc {
        font-size: 18px;
        color: #626469;
      }
    }
    .calc-block {
      .title {
        font-family: "T2 Halvar Breitschrift", sans-serif;
        font-weight: bold;
        margin-bottom: 35px;
        font-size: 24px;
        color: $text-default;
      }
    }
  }
  .fat-form {
    .title {
      margin-bottom: 30px;
      font-size: 22px;
      font-family: "T2 Halvar Breitschrift", sans-serif;
      line-height: 26px;
      font-weight: bold;
      color: $text-default;
    }
    .form-group {
      margin: 0 15px 0 0;
      flex-grow: 1;
      max-width: 538px;
    }
    .btn-block {
      width: auto;
    }
    .product-info {
      display: none;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .product-data {
      margin-bottom: 20px;
      font-size: 14px;
      color: #979797;
      .product {
        margin-bottom: 5px;
      }
      .name {
        margin-right: 15px;
        flex-grow: 1;
        width: 120px;
      }
      .digits {
        display: inline-flex;
        align-items: center;
        width: 140px;
        .num {
          color: $text-default;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .all-link {
      margin-bottom: 22px;
    }
    &.active {
      .block {
        display: none;
      }
      .product-info {
        display: block;
      }
    }
  }
  .toggle-wrap {
    &.active {
      .block {
        display: none;
      }
      .product-info {
        display: block;
      }
    }
  }
  &.article-block {
    .img-wrap-block {
      position: relative;
      .widget-title {
        position: absolute;
        bottom: 50px;
        margin-left: -325px;
        left: 50%;
        color: $white;
        z-index: 1;
        width: 650px;
        text-align: center;
      }
      &:after {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(359.67deg,#1f2229 10%,rgba(31,34,41,0) 85%);
      }
    }
    .img-block {
      margin-bottom: 30px;
      width: 100%;
      height: 375px;
      background-position: center;
    }
	.social-list {
      position: absolute;
      bottom: 45px;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
    }
    .text-block {
      margin: 0 auto;
      max-width: 730px;
      color: #626469;
      font-size: 16px;
    }
  }
  &.category {
    padding: 60px 0 0;
  }
  .discount-list {
    display: flex;
    background-color: $white;
    flex-flow: row wrap;
    overflow-x: auto;
    .element {
      margin: 0 20px 20px 0;
      max-width: 320px;
      flex-shrink: 0;
      box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
      .top-block {
        img {
          width: 100%;
        }
      }
      img {
        display: block;
      }
      .info-block {
        align-items: center;
        padding: 16px 8px 19px;
      }
      .title {
        flex-grow: 1;
        font-size: 14px;
        max-width: 185px;
      }
      .partner-logo {
        margin-left: 10px;
        flex-shrink: 0;
        max-width: 95px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}

.geo-block {
  padding-top: 20px;
  color: $text-default;
  .title {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .custom-select-block {
    max-width: 330px;
  }
  .btn {
    padding: 11px 32px;
    &:first-child {
      margin-right: 20px;
    }
  }
  .btn-block {
    margin-bottom: 30px;
  }
  .gess-block {
    margin-bottom: 20px;
    .ges-title {
      font-size: 18px;
      font-weight: bold;
      color: $text-default;
    }
    a {
      font-size: 14px;
      color: $text-default;
      @include link-line(#B2B2B2);
    }
  }
}

.cat-block {
  padding: 30px 15px 10px;
  background-color: #F0F1F2;
  .d-flex {
    overflow-y: auto;
    padding-bottom: 15px;
  }
  a {
    margin-right: 35px;
    flex-shrink: 0;
    color: $text-default;
    &.active {
      font-weight: bold;
    }
  }
}

.quiz-random-block {
  padding: 20px 0 26px;
  color: $white;
  background-color: $bgDark;
  .title {
    margin-bottom: 18px;
    font-family: "T2 Halvar Breitschrift", sans-serif;
    font-size: 28px;
    font-weight: bold;
  }
  .desc {
    margin-bottom: 20px;
    font-size: 14px;
  }
}

.table {
  font-size: 14px;
  thead {
    tr {
      border-bottom: 1px solid $black;
    }
    th {
      border-top: none;
      border-bottom: none;
      //font-family: "T2 Halvar Breitschrift", sans-serif;
      font-size: 14px;
      font-weight: bold;
    }
  }
  tbody {
    tr {
      //border-bottom: 1px solid #D1DBD6;
    }
  }
  th {
    //padding: 20px 10px;
    padding: 8px;
    //font-family: 'T2 Halvar Breitschrift', sans-serif;
    color: $black;
    font-weight: bold;
  }
  td {
    //padding: 30px 10px;
    padding: 8px;
    color: $text-default;
  }
  &.vaccinations {
    border-top: none;
    width: auto;
    thead {
      tr:nth-child(1),
      tr:nth-child(2) {
        th {
          border-right: none;
        }
      }
    }
    .border-right {
      border-right: 1px solid #D1DBD6;
      &.border-black {
        border-right-color: $black !important;
      }
    }
    th,td {
      padding: 0;
      width: 62px;
      border-top: none;
      border-right: 1px solid #D1DBD6;
    }
    .cell {
      padding: 0 10px;
      width: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &.wide {
        width: auto;
      }
    }
    .double {
      height: 86px;
    }
    .single {
      height: 43px;
    }
    .dark {
      color: $white;
    }
  }
}

.gray1 {
  background-color: #D1D8D6;
}
.gray2 {
  background-color: #7A7A7A;
}
.gray3 {
  background-color: $gray3;
}
.gray5 {
  background-color: $gray5;
}

.dark {
  background-color: $bgDark;
}

.green {
  background-color: $green;
}
.yellow {
  background-color: #D7FF38;
}
.red {
  background-color: #FF59A3;
}
.pink {
  background-color: $pink;
}
.blue {
  background-color: #34CAFF;
}
.blue2 {
  background-color: $blue3;
}

.text-blue {
  color: $blue;
}


.small-content-list {
  margin-left: -36px;
  padding-bottom: 5px;
  display: flex;
  flex-flow: row wrap;
  overflow-x: auto;
  @include smooth-scroll();
  scroll-snap-type: x proximity;
  color: $text-default2;
  &.square {
    .img-block {
      width: 160px;
      height: 160px;
    }
  }
  .element {
    position: relative;
    margin: 0 0 35px 36px;
    scroll-snap-align: start;
    &:last-child {
      margin-right: 0;
    }
  }
  .img-block {
    margin-bottom: 10px;
    width: 155px;
    height: 210px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .title {
    font-family: 'T2 Halvar Breitschrift', sans-serif;
  }
  .link {
    @include all-cover-link();
  }
}

.slider-block {
  display: flex;
  height: 500px;
  background-color: $gray;
  .slider-content {
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
  }
  .slide {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    display: none;
    &.active {
      display: block;
    }
    .img-block {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      img {
        margin: 0 0 0 auto;
        display: block;
      }
    }
    .info-block {
      position: absolute;
      bottom: 30px;
      z-index: 1;
      .title {
        margin-bottom: 35px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        color: $black;
        font-weight: bold;
        font-size: 48px;
        line-height: 60px;
      }
    }
  }
  .btn {
    min-width: 200px;
  }
  .slider-control {
    padding: 20px 20px;
    max-width: 280px;
    flex-grow: 1;
    border-left: 2px solid rgba(31, 34, 41, 0.4);
    .control {
      position: relative;
      margin-bottom: 20px;
      display: block;
      font-size: 21px;
      line-height: 29px;
      color: rgba($text-default, .4);
      cursor: pointer;
      &:before {
        display: none;
        content: '';
        position: absolute;
        top: -4px;
        left: -22px;
        height: 40px;
        width: 2px;
        background-color: #191818;
      }
      &.active,
      &:hover {
        &:before {
          display: block;
        }
        color: $text-default;
      }
    }
    .more-link {
      font-size: 18px;
      text-decoration: none;
      color: rgba($text-default, .4);
      .icon {
        margin-left: 10px;
        font-size: 13px;
      }
      &:hover {
        color: $text-default;
      }
    }
  }
}

.pagination {
  margin-bottom: 0;
}

.page-item {
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-size: 16px;
  min-width: 42px;
}

.page-item.active .page-link {
  border-color: $text-default;
  background-color: $text-default;
  z-index: 3;
}

.page-link {
  color: $text-default;
  padding: .65rem 1rem;
  border-radius: 0 !important;
  background-color: transparent;
  border-color: #D1DBD6;
  &:hover {
    background-color: #e9ecef;
  }
  &:focus {
    box-shadow: none;
  }
  .icon {
    margin-top: 2px;
    display: inline-block;
    vertical-align: top;
    color: #8F9399;
  }
}

.form-group {
  margin-bottom: 0px;
  position: relative;
  label {
    //margin-bottom: 15px;
  }
  .error-message {
    display: none;
    margin-top: 10px;
    align-items: center;
    font-size: 13px;
    &:before {
      content: '';
      margin-right: 5px;
      flex-shrink: 0;
      width: 21px;
      height: 21px;
      background-image: url("/static/img/svg/alert.svg");
    }
    color: $errorText2;
  }
  &.error {
    .error-message {
      display: flex;
    }
  }
}

form {
  max-width: 600px;
  padding: 25px 18px;
  background-color: $white;
  .title-block {
    position: relative;
    color: $text-default;
    margin-bottom: 30px;
    .title {
      font-family: 'T2 Halvar Breitschrift';
      font-weight: bold;
      font-size: 28px;
    }
  }
  .text {
    margin-bottom: 10px;
    line-height: 22px;
  }
  .close {
    position: absolute;
    top: 5px;
    right: 0;
    width: 32px;
    height: 32px;
    background: url('../img/svg/close-black.svg');
  }
  .form-group {
    width: 100%;
  }
  .btn-block {
    margin-bottom: 45px;
    .big {
      min-width: 197px;
    }
  }
  .offer-block {
    text-align: center;
    max-width: 445px;
    font-size: 12px;
    color: $gray2;
  }
  a {
    color: $gray2;
  }
  .sub-title-block {
    text-align: center;
    margin-bottom: 20px;
    .title {
      margin-bottom: 25px;
      font-size: 22px;
      color: $text-default;
    }
    .number {
      font-size: 16px;
      color: $text-default;
    }
    .help-text {
      color: #929292
    }
    .text {
      font-size: 18px;
      color: #929292;
    }
  }
  .twofa {
    margin-bottom: 32px;
  }
  .phone-block {
    margin: 0 auto;
    max-width: 250px;
    text-align: center;
    .form-group {
      .digit {
        //margin: 0 8px;
        background: $white;
        //border: 2px solid #ABAEB3;
        border-radius: 4px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-size: 32px;
        font-weight: bold;
        width: 60px;
        height: 70px;
        text-align: center;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
  }
  .collection {
    margin: 0 auto;
    .custom-input-block {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 32px;
      }
    }
  }
  .auth {
    .custom-input-block {
      margin-bottom: 10px;
    }
  }
  &.auth-form {
    .container-fluid {
      height: 100%;
    }
    .d-flex {
      height: 100%;
    }
    .desc {
      text-align: center;
      font-size: 12px;
      color: $gray2;
    }
    .form-group {
      margin: 0 auto;
    }
  }
  &.service-form {
    position: relative;
    padding: 0;
    overflow-y: auto;
    height: 100%;
    .wrap {
      padding: 20px 15px 40px;
    }
    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      background: url('../img/svg/close-black.svg');
    }
    .img-block {
      img {
        margin: 0;
        display: block;
        width: 100%;
      }
    }
    .title {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 28px;
      color: $text-default;
      margin-bottom: 18px;
    }
    .text {
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 20px;
      color: #626469;
    }
    .info {
      font-size: 14px;
      color: #626469;
    }
    .btn {
      margin-bottom: 20px;
    }
    a {
      font-size: 16px;
      color: $text-default;
      @include link-line();
    }
  }
  &.events-form {
    position: relative;
    padding: 0;
    overflow-y: auto;
    height: 100%;
    .wrap {
      padding: 17px 30px 40px 15px;
      background-color: $gray;
    }
    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      background: url('../img/svg/close-black.svg');
    }
    .img-block {
      img {
        margin: 0;
        display: block;
        width: 100%;
      }
    }
    .title {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 28px;
      color: $text-default;
      margin-bottom: 18px;
    }
    .sub-block {
      margin-bottom: 15px;
      font-size: 13px;
    }
    a {
      color: $text-default;
      text-decoration: underline;
    }
  }
  &.spec-form {
    position: relative;
    padding: 0;
    max-width: 735px;
    width: 100vw;
    .img-block {
      flex-shrink: 0;
    }
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 32px;
      height: 32px;
      background: url('../img/svg/close-black.svg');
    }
    .title {
      margin: 0 40px 18px 0;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 28px;
      color: $text-default;
    }
    .text {
      margin-bottom: 25px;
      color: #626469;
      font-size: 16px;
    }
    .wrap {
      padding: 28px 14px 40px 24px;
    }
    .btn {
      margin-bottom: 15px;
      padding: 11px 45px;
    }
  }
  &.services-access {
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      width: 32px;
      height: 32px;
      background: url('../img/svg/close-black.svg');
    }
    .title {
      margin: 0 40px 25px 0;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 28px;
      color: $text-default;
    }
    .text {
      margin-bottom: 25px;
      color: #626469;
      font-size: 16px;
    }
    .wrap {
      padding: 45px 60px 60px 45px;
    }
  }
  &.specialist-form {
    padding: 0;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      opacity: 1;
    }
    .top-block {
      background-color: #F5F5FA;
      .inner-wrap {
        padding: 20px 35px;
      }
      .title {
        margin-bottom: 20px;
        font-size: 22px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-weight: bold;
        color: $text-default;
      }
      .avatar {
        margin-right: 15px;
        flex-shrink: 0;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        overflow: hidden;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .text-block {
        .name {
          font-family: 'T2 Halvar Breitschrift', sans-serif;
          color: $text-default;
          font-size: 16px;
        }
        .text {
          max-width: 235px;
          font-size: 13px;
          color: #8F9399;
        }
      }
    }
    .bot-block {
      .inner-wrap {
        padding: 10px 35px 52px;
      }
      .custom-input-block {
        textarea {
          resize: none;
          height: 100px;
        }
      }
      .btn {
        padding: 11px 45px;
        width: 230px;
      }
    }
  }
}

.map-frame {
  position: relative;
  overflow: hidden;
  &:hover {
    .map-tip {
      display: flex;
    }
  }
  .map-tip {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    color: $white;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.breadcrumbs {
  padding: 12px 0;
  a {
    color: $text-default;
  }
  .icon {
    margin: 0 5px;
    font-size: 21px;
  }
  cursor: default;
  &.gray {
    background-color: $gray;
  }
  &.dark {
    background-color: $bgDark;
    a,span {
      color: $white;
    }
  }
}

.category-block {
  padding: 10px 0;
  color: $white;
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: $gray2;
}

.calories-calculator {
  .cal {
    color: #8F9399;
  }
  .plan-block {
    font-size: 14px;
    .text {
      margin-bottom: 14px;
      color: $text-default;
    }
    .desc {
      margin-bottom: 24px;
      color: #626469;
    }
    .btn {
      margin-bottom: 26px;
    }
  }
  .result {
    color: #626469;
    font-size: 14px;
    .dig {
      font-size: 18px;
      font-weight: bold;
      color: $text-default;
    }
  }
  .calculator-article-block {
    .data-block {
      margin-bottom: 30px;
      font-size: 16px;
    }
    .desc-block {
      margin-bottom: 20px;
      a {
        color: $text-default;
        @include link-line();
      }
    }
    .article-block {
      margin-bottom: 30px;
      .title {
        margin-bottom: 18px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-size: 22px;
        line-height: normal;
        font-weight: bold;
        color: $text-default;
      }
      p {
        color: #626469;
      }
      a {
        display: flex;
        align-items: center;
        color: $text-default;
        text-decoration: none;
        .text  {
          color: $text-default;
          margin-bottom: 0;
        }
        .icon {
          margin: 3px 0 0 3px;
        }
      }
    }
  }
  .toggle-block {
    filter: grayscale(100%);
    background: url('/static/img/slider1.png') right center / 50% no-repeat;
  }
}

.container-fluid,
.container {
  .tab-wrap {
    //margin: 0 -15px;
    .tab-content-list {
      //padding: 0 15px;
    }
  }
}


.tab-list {
  display: flex;
  //border-bottom: 1px solid #D1DBD6;
  .tab {
    position: relative;
    padding: 8px 12px;
    text-align: center;
    flex-grow: 1;
    //color: #8F9399;
    cursor: pointer;
    &.active {
      color: $text-default;
      &:after {
        content:'';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: $bgDark;
      }
    }
  }
  &.gray {
    padding: 30px 15px;
    border-bottom: none;
    background-color: #F0F1F2;
    .tab {
      flex-grow: 0;
      padding: 0;
      margin-right: 37px;
      color: #8F9399;
      &.active {
        color: $text-default;
        &:after {
          display: none;
        }
      }
    }
  }
}
.tab-content-list {
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
}

.tile-list {
  display: flex;
  margin-bottom: 40px;
  overflow-x: auto;
  @include smooth-scroll();
  scroll-snap-type: x proximity;
  .tile {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    min-width: 205px;
    justify-content: space-between;
    padding: 30px 30px 35px;
    width: 25%;
    color: $text-default;
    background-color: #E8E8ED;
    border-right: 1px solid #D1DBD6;
    scroll-snap-align: start;
    .img-block {
      margin-bottom: 15px;
      overflow: hidden;
      border-radius: 50%;
      @include aspect-ratio(1, 1, 'inner-block');
      .inner-block {
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .title {
      margin-bottom: 30px;
      text-align: center;
      font-size: 22px;
      line-height: 31px;
      font-weight: inherit;
    }
    .btn {
      width: 100%;
      max-width: 205px;
    }
    .all-cover-link {
      z-index: 1;
      @include all-cover-link();
    }
    &:last-child {
      border: none;
    }
  }
}

.mb30 {
  margin-bottom: 30px;
}
.underline {
  text-decoration: underline;
}

.habits-list {
  margin-bottom: 10px;
  .habit-block {
    margin-bottom: 8px;
    padding: 10px;
    background-color: $gray;
    border-radius: 4px;
    .icon {
      margin-right: 6px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      color: $white;
      border-radius: 50%;
      background-color: $text-default;
      &.icon-cigar {
        &:before {
          margin: -4px 0 0 -3px;
          font-size: 22px;
        }
      }
    }
    .title {
      font-weight: bold;
    }
    .title-block {
      margin-bottom: 8px;
    }
    .desc {
      font-size: 12px;
      color: #626469;
    }
  }
}

.notification {
  position: relative;
  border: 1px solid #dadada;
  .close {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 15px;
    color: $text-default;
  }
  .img-block {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    img {
      display: block;
      max-width: 50px;
    }
  }
  .text-block {
    padding: 6px 7px 7px 9px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: $text-default;
    }
    .text {
      font-size: 12px;
      color: #686868;
    }
  }
}

.dashed {
  margin: 0;
  list-style: none;
  padding-left: 1em;
  li {
    &:before {
      display: inline-block;
      content: "-";
      width: 10px;
      margin-left: -10px;
    }
  }
}
.discounts-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  .discount-block:nth-child(4) {
    grid-column-start: span 3;
  }
}
@media (max-width: $screen-sm) {

  .container-fluid {
    padding: 0;
  }
  .content-wrap {
    border-radius: 16px;
    padding: 12px 12px 27px 12px;
  }
  .title-top {
    font-size: 24px;
    line-height: 22px;
  }
  .title {
    font-size: 22px;
    line-height: 20px;
  }
  .container-wrap {
    gap: 24px;
  }
  .widget-block {
    border-radius: 16px;
    padding: 12px;
  }
  .widget-box {
    //padding: 50px 0;
    .widget-title {
      margin-bottom: 18px;
      font-size: 28px;
      line-height: 125%;
      &.small {
        margin-bottom: 18px;
        font-size: 22px;
        line-height: normal;
      }
    }
    .widget-desc {
      font-size: 14px;
      line-height: 20px;
    }
    .content-list {
      padding-bottom: 20px;
      margin-left: 0;
      scroll-snap-type: x proximity;
      overflow-x: auto;
      @include hideScroll();
      .element {
        margin: 0 20px 0 0;
        scroll-snap-align: start;
        min-width: 289px;
        .title {
          margin-bottom: 0;
          font-size: 16px;
        }
        .img-wrapper {
          @include aspect-ratio(13, 9, 'img-block')
        }
        &:nth-child(3n) {
          margin-right: 20px;
        }
      }
      &.wrap-big {
        margin-left: 0;
        flex-flow: row nowrap;
        .element {
          margin: 0 32px 0 0;
          width: 211px;
        }
      }
      &.discounts {
        .element {
          margin-right: 20px;
          width: 250px;
          .img-wrapper {
            @include aspect-ratio(14.2, 16, 'img-block')
          }
        }
        .elem-wrapper {
          margin-bottom: 16px;
          padding: 9px 16px;
          .img-wrapper {
            margin-bottom: 0;
          }
          .img-block {
            background-size: contain;
          }
        }
      }
      &.square-small {
        .element {
          max-width: none;
        }
      }
    }
    &.quiz {
      //padding: 10px 0 20px;
      .widget-title-block {
        align-items: flex-start;
        flex-flow: column nowrap;
        .widget-title {
          order: 2;
        }
        .category {
          margin-bottom: 5px;
          order: 1;
          font-size: 14px;
        }
        .category-img {
          margin-bottom: 20px;
          img {
            display: block;
          }
        }
      }
      .title-question {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .desc {
        margin-bottom: 10px;
        font-size: 14px;
      }
      .element {
        width: 100%;
        .img-wrapper {
          margin-bottom: 5px;
          @include aspect-ratio(9, 12.5, 'img-block');
        }
        .title {
          //text-align: center;
          font-size: 16px;
        }
		&.active {
			.img-wrapper {
			  &:after {
				top: -10px;            
				right: -10px;
				width: 25px;
				height: 25px;
				border-radius: 40px;
				border: 4px solid black;
			  }
			}
		}
      }
      .quiz-question-block {
        margin-bottom: 20px;
        .title {
          margin-bottom: 6px;
          font-size: 16px;
        }
        .element {
          width: calc(50% - 8px);
          .img-wrapper {
            margin-bottom: 6px;
            @include aspect-ratio(9, 13, 'img-block')
          }
          .title {
            text-align: center;
          }
        }
      }
      .quiz-page-result-block {
        .table {
          td {
            font-size: 14px;
            &:first-child {
              max-width: 55%;
            }
          }
        }
      }
    }
    &.calc-default {
      .widget-title-block {
        margin-bottom: 20px;
        .desc {
          font-size: 14px;
        }
      }
      .calc-block {
        .title {
          margin-bottom: 18px;
          font-size: 22px;
        }
      }
      .custom-input-block {
        margin-bottom: 30px;
      }
    }
    .fat-form {
      .custom-input-block {
        margin-bottom: 30px;
      }
      .product-info {
        margin-bottom: 10px;
      }
      .btn-calc.small {
        padding: 11px 14px;
      }
    }
    &.article-block {
      margin-bottom: 10px;
      .widget-title {
        font-size: 22px;
      }
	  .social-list {
        left: 10px;
        bottom: 14px;
        transform: none;
        .social-block {
          margin: 0 0 10px 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .img-wrap-block {
        .widget-title {
          position: relative;
          top: inherit;
          bottom: inherit;
          left: inherit;
          margin-left: inherit;
          width: auto;
          text-align: left;
          color: $text-default;
        }
        &:after {
          display: none;
        }
      }
      .img-block {
        margin-bottom: 10px;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        height: 200px;
      }
      .text-block {
        color: #626469;
        font-size: 14px;
      }
    }
    .discount-list {
      margin-bottom: 15px;
      .element {
        max-width: inherit;
        width: 100%;
      }
    }
    .all-link {
      font-size: 18px;
      @include link-line($black);
      &:hover {
        &:after {
          background-color: $black;
        }
      }
    }
    &.discounts-common {
      .content-list {
        margin-bottom: 15px;
        @include scrollbar(auto, 7px, #DADADA, $blue);
        @include hideScroll(block);
        .element {
          .img-wrapper {
            border: 1px solid $gray2;
          }
        }
      }
    }
  }
  .banner-services {
    border-radius: 12px;
    padding: 12px;
    gap: 16px;
    background-color: $gray3;
  }
  .banner {
    height: 256px;
    border-radius: 12px;
    align-content: end;
    .info-block {
      border-radius: 12px;
      padding: 12px;
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }
    &.podcast {
      background: url('/static/img/banners/podcast-mob.jpg') center / cover no-repeat;
    }
    &.eye {
      background: url('/static/img/banners/woman-eye-mob.jpg') center / cover no-repeat;
    }
    &.spec {
      //height: 536px;
      height: unset;
      background: url('/static/img/banners/doc-mob.jpg') top center white no-repeat;
      color: white;
      background-position-x: center;
      background-position-y: 8px;
      background-size: calc(100% - 16px);
      .bg {
        height: 235px;
        background: linear-gradient(180.4deg, rgba(245, 245, 250, 0) 12.92%, #ffff 90.12%, #ffff 97.75%);
      }
      .info-block {
        background-color: $pink;
      }
    }
    &.smok {
      .info-block {
        height: unset;
      }
    }
    &.wate {
      background: url('/static/img/banners/wate.jpg') no-repeat;
      background-position: center;
      background-size: cover;
      .info-block {
        height: unset;
        background-color: $blue2;
      }
    }
    &.timer {
      background: url('/static/img/banners/timer-mob.jpg') no-repeat;
      background-size: cover;
      .info-block {
        height: unset;
        background-color: $green;
      }
    }
  }
  .double-banner {
    flex-direction: column-reverse;
    gap: 8px;
    .info-block {
      border-radius: 12px;
      padding: 12px;
    }
    .img-block {
      background: url('/static/img/banners/woman-mob.jpg')  no-repeat;
      background-size: cover;
      width: unset;
      min-height: 220px;
      border-radius: 12px;
    }
  }
  .small-content-list {
    flex-flow: row nowrap;
    .title {
      text-align: center;
    }
    &.products-all {
      flex-flow: row wrap;
    }
  }
  .slider-block {
    .slide {
      .info-block {
        .title {
          font-size: 36px;
          line-height: 43px;
        }
      }
    }
    .slider-control {
      .control {
        font-size: 18px;
        line-height: 25px;
      }
      .more-link {
        white-space: normal;
      }
    }
  }
  .widget-form-block {
    .title-block {
      margin-bottom: 15px;
      .title {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 125%;
      }
      .desc {
        margin-bottom: 25px;
        font-size: 13px;
      }
    }
    .btn-block {
      margin: 5px 0 0;
      text-align: left;
      .btn {
        margin-bottom: 10px;
      }
      .desc {
        font-size: 13px;
        color: #8F9399;
        a {
          color: $white;
        }
      }
    }
    .time-setter-block {
      flex-flow: row wrap;
      font-size: 14px;
      .element {
        padding-right: 0;
      }
      .time,
      .days {
        width: 100%;
      }
      .time {
        margin-bottom: 5px;
      }
      .days {
        margin-bottom: 10px;
      }
      .del {
        text-align: right;
      }
    }
    .text {
      font-size: 14px;
    }
    .result-block {
      margin-bottom: 40px;
      .result-row {
        display: flex;
        justify-content: space-between;
      }
      .name {
        flex-grow: 1;
        font-size: 14px;
        color: #D1D1D1;
      }
      .data {
        white-space: nowrap;
        font-size: 16px;
        color: #D1D1D1;
      }
      .digit {
        color: $white;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .table {
    font-size: 13px;
    td {
      padding: 15px 10px;
    }
  }
  .map-frame {
    .map-tip {
      display: flex;
    }
  }
  .tile-list {
    display: flex;
    flex-flow: column nowrap;
    .tile {
      border-top: 1px solid #D1DBD6;
      border-right: none;
      padding: 20px 0;
      width: 100%;
      background-color: transparent;
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-top: 1px solid #D1DBD6;
      }
      .img-block {
        margin: 0 10px 0 0;
        width: 77px;
        flex-shrink: 0;
      }
      .top-block {
        position: relative;
        padding-right: 18px;
        display: flex;
        align-items: center;
        &:after {
          content:'';
          position: absolute;
          right: 0;
          width: 7px;
          height: 13px;
          background: url('../img/svg/more.svg') no-repeat;
        }
      }
      .title {
        margin: 0;
        font-size: 14px;
        line-height: normal;
        font-weight: normal;
        text-align: left;
      }
      .bot-block {
        display: none;
      }
    }
  }
  .calories-calculator {
    .toggle-block {
      background: none;
    }
  }
  form {
    &.spec-form {
      .img-block {
        img {
          width: 100%;
        }
      }
      .close {
        top: 18px;
        right: 18px;
      }
      .wrap {
        padding: 16px 16px 32px;
      }
      .title {
        margin-bottom: 18px;
        font-size: 24px;
      }
      .text {
        font-size: 14px;
      }
    }
    &.services-access {
      .close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 32px;
        height: 32px;
        background: url('../img/svg/close-black.svg');
      }
      .title {
        margin: 0 55px 30px 0;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-weight: bold;
        font-size: 28px;
        color: $text-default;
      }
      .text {
        margin-bottom: 50px;
        color: #626469;
        font-size: 14px;
      }
      .wrap {
        padding: 20px 16px ;
      }
    }
    &.specialist-form {
      .close {
        top: 20px;
        right: 18px;
      }
      .top-block {
        .inner-wrap {
          padding: 22px 15px;
        }
      }
      .bot-block {
        .inner-wrap {
          padding: 18px 15px;
        }
      }
    }
  }
  .discounts-block {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    .discount-block:nth-child(4) {
      //grid-column-start: span 3;
      grid-column-start: unset;
    }
  }
}

@media (max-width: $screen-xs) {
  .btn,input[type='button'] {
    //padding: 11px 35px;
    line-height: 16px;
  }
  .widget-box {
    //padding: 30px 0;
    .content-list {
      display: flex;
      &.wrap-all {
        flex-flow: row wrap;
        .element {
          min-width: inherit;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .quiz-block {
      flex-direction: column;
    }
  }
  .table-block {
    .title {
      text-align: center;
    }
  }
  .small-content-list {
    margin-left: 0;
    .element {
      margin-left: 25px;
    }
    &.products-all {
      padding-bottom: 15px;
      margin-left: 0;
      flex-flow: row wrap;
      justify-content: space-between;
      .element {
        margin: 0 0 15px 0;
        width: calc(50% - 5px);
        .img-wrapper {
          @include aspect-ratio(9, 12.5, 'img-block');
        }
        .img-block {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .slider-block {
    flex-flow: column nowrap;
    height: auto;
    .slide {
      padding: 30px 10px 0;
      .img-block {
        position: relative;
        img {
          margin: 0 auto;
          max-width: 100%;
        }
      }
      .info-block {
        padding-bottom: 30px;
        .title {
          margin-bottom: 20px;
          font-size: 28px;
          line-height: 33px;
        }
      }
    }
    .slider-control {
      padding: 15px 5px;
      max-width: inherit;
      border-top: 1px solid rgba(31, 34, 41, 0.2);
      border-left: none;
      .control-menu {
        display: flex;
        flex-flow: row wrap;
        .control {
          margin: 0 35px 10px 0;
          font-size: 14px;
          line-height: 20px;
          &.active {
            &:before {
              display: none;
            }
          }
        }
        .more-link {
          flex-grow: 1;
          justify-content: space-between;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  form {
    max-width: 600px;
    background-color: $white;
    .title-block {
      margin-bottom: 30px;
      .title {
        text-align: left;
        font-size: 24px;
      }
    }
    .form-group {
      max-width: none;
      width: 100%;
    }
    .btn-block {
      margin-bottom: 30px;
      text-align: center;
      .big {
        min-width: 155px;
      }
    }
    .offer-block {
      font-size: 12px;
      color: $gray2;
    }
    .phone-block {
      max-width: inherit;
      .sub-title-block {
        text-align: left;
      }
      .btn-block {
        text-align: left;
      }
      .digit {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.podcast-list {
  display: flex;
  gap: 36px;
  overflow-x: auto;
  .podcast-block {
    // width: calc(25% - 36px);
    flex: 1 1 calc((100% / 4) - 2rem);
    flex-shrink: 0;
    .img-block {
      margin-bottom: 8px;
      aspect-ratio: 16/12;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .name {
      margin-bottom: 8px;
      font-size: 18px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
    }
    .job {
      margin-bottom: 8px;
      font-size: 15px;
      color: #8F9399;
    }
    .play-block {
      .player-block {
        display: none;
      }
      &.active {
        .player-block {
          display: block;
        }
        .icon-play {
          &:before {
            content: "\e914";
          }
        }
      }
    }
    .icon-play-block {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
    }
    .icon-play {
      color: $white;
      flex-shrink: 0;
      font-size: 35px;
      border-radius: 50%;
      overflow: hidden;
      background-color: $text-default;
    }
    .timer {
      font-size: 12px;
      color: #798795;
    }
    .progress-bar {
      margin-bottom: 8px;
      position: relative;
      background: #E8E8ED;
      height: 6px;
      overflow: visible;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.09);
      border-radius: 26px;
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: visible;
        background-color: $blue;
        &:after {
          position: absolute;
          right: 0;
          content: '';
          margin-top: -5px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $blue;
        }
      }
    }
  }
  @media (max-width: $screen-sm) {
    gap: 18px;
    .podcast-block {
      flex: 1 0 calc(100% - 35px);
    }
  }
}

.podcast-block-wrap {
  padding-bottom: 40px;
  @include title-main();
  .carousel {
    margin-bottom: 18px;
    position: relative;
    .control-block {
      @include control-block(-64px);
    }
  }
}


.content-grid-block-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  .content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  .content-block {
    color: $text-default;
    padding: 8px;
    border-radius: 16px;
    margin-bottom: 0;
    &.img-block {
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      padding: 12px;
      border-radius: 12px;
      background-color: $gray5;
      .title {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
    .title {
      //font-size: 24px;
      //line-height: 22px;
    }
    .content-grid {
      grid-template-columns: repeat(1, 1fr);
      gap: 8px;
    }
    .content-block {
      //width: unset;
      .title {
        //font-size: 14px;
      }
    }
  }
}
