@import "../variables";

@mixin font-tele2-huge() {
  font-family: 'Standard Stencil Bold', sans-serif;
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;

  @media (max-width: $screen-sm) {
    font-size: 28px;
    line-height: 33px;
  }
}

@mixin font-tele2-title() {
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-size: 38px;
  font-weight: 700;

  @media (max-width: $screen-sm) {
    font-size: 28px;
    line-height: 42px;
  }
}

@mixin font-tele2-sub-title() {
  font-family: 'T2 Halvar Breitschrift', sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;

  @media (max-width: $screen-sm) {
    font-size: 22px;
    line-height: 31px;
  }
}

@mixin font-tele2-large() {
  font-size: 18px;
  line-height: 25px;

  @media (max-width: $screen-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin font-tele2-medium() {
  font-size: 16px;
  line-height: 22px;

  @media (max-width: $screen-sm) {
    font-size: 12px;
    line-height: 17px;
  }
}

@mixin font-tele2-small() {
  font-size: 14px;
  line-height: 20px;

  @media (max-width: $screen-sm) {
    // same size
  }
}

