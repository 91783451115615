.widget-box.events {
  padding-bottom: 50px;
  .top-bg-wrap {
    position: relative;
    height: 342px;
    margin-bottom: 45px;
    .title {
      position: absolute;
      padding: 0 18px;
      width: 100%;
      bottom: 60px;
      font-size: 38px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      color: $white;
      text-align: center;
    }
  }
  .top-bg {
    position: relative;
    height: 100%;
    background-size: cover;
    &:before {
      content:'';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(359.46deg,#1f2229 12.35%,rgba(31,34,41,0) 86.56%);
    }
  }
  .article-block {
    clear: both;
  }
  .article-image {
    &.left {
      margin: 0 26px 16px 0;
      float: left
    }
    &.right {
      margin: 0 0 16px 26px;
      float: right
    }
  }
  .special-block {
    margin-bottom: 20px;
    position: relative;
    padding: 35px 25px;
    background-color: #34CAFF;
    p {
      margin-bottom: 0;
      color: $white;
      font-size: 20px;
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
    }
  }
  .sized {
    margin-right: 370px;
  }
  .soon-block {
    background-color: #F5F5F5;
    .img-block {
      flex-shrink: 0;
      width: 440px;
      height: 265px;
      background-size: cover;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .info-block {
      padding: 20px 36px 30px;
      color: #626469;
      .title {
        margin-bottom: 12px;
        font-size: 28px;
        font-weight: bold;
      }
      ul {
        margin: 0 0 0 20px;
        padding: 0;
        li {
          margin-bottom: 15px;
        }
      }
      .info {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .widget-box.events {
    padding: 20px 0 30px;
    .top-bg-wrap {
      flex-flow: column nowrap;
      display: flex;
      height: 200px;
      margin-bottom: 10px;
      .title {
        padding-left: 0;
        text-align: left;
        bottom: inherit;
        order: 1;
        position: relative;
        color: $text-default;
        font-size: 22px;
        margin-bottom: 18px;
      }
      .top-bg {
        order: 2;
      }
    }
    .sized {
      margin-right: 0;
    }
    .special-block {
      margin: 100px 0 10px;
      min-height: 100px;
      img {
        max-height: 200px;
      }
    }
    .soon-block {
      .img-block {
        width: 100%;
        height: 120px;
        background-size: cover;
      }
      .info-block {
        padding: 18px 8px 20px;
        .title {
          margin-bottom: 10px;
          font-size: 22px;
        }
        ul {
          font-size: 16px;
          li {
            margin-bottom: 8px;
          }
        }
        .info {
          font-size: 13px;
        }
      }
    }
  }
}
