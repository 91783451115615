.video-page-wrap {
  .content-list {
    padding-bottom: 5px;
    flex-flow: row wrap;
    .element {
      margin-bottom: 28px;
    }
  }
}

@media (max-width: $screen-sm) {

}

@media (max-width: $screen-xs) {
  .video-page-wrap {
    .content-list {
      margin-left: 0;
      margin-bottom: -28px;
      flex-flow: column nowrap;
      .element {
        margin: 0 0 20px 0;
        width: 100%;
      }
    }
  }
}
