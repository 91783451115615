@import "../../variables";

@mixin button-clear-dark() {
  @include button-impl($text-default, $black, transparent);
  @include button-size-regular();

  &:hover {
    color: rgba(31, 34, 41, .7);
    border-color: rgba(31, 34, 41, .7);
  }
}

@mixin button-clear-white() {
  @include button-impl($white, $white, transparent);
  @include button-size-regular();

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(253, 253, 253, 0.9);
  }
}

@mixin button-clear-white-small() {
  @include button-clear-white();
  @include button-size-small();
}

@mixin button-dark() {
  @include button-impl($white, $black, $black);
  @include button-size-regular();

  color: $white;
  border-color: $text-default;
  background-color: $text-default;

  &:hover {
    color: $white;
    border-color: $header-link;
    background-color: $header-link;
  }
}

@mixin button-size-regular() {
  padding: 17px 45px;
}

@mixin button-size-small() {
  padding: 10px 45px;
}

@mixin button-size-xs() {
  padding: 5px 22px;
}

@mixin button-impl($text-color, $border-color, $background-color) {
  display: inline-block;
  vertical-align: top;
  width: auto;
  color: $text-color;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  outline: none !important;
  cursor: pointer;
  user-select: none;
  border: 2px solid $border-color;
  background-color: $background-color;
  border-radius: 30px;

  &:focus {
    box-shadow: none;
  }
}