.interesting-page-title-block {
  .top-border {
    margin-bottom: -25px;
    padding-bottom: 41px;
    padding: 16px 16px 49px 16px;
  }
  .promo-block {
    position: relative;
    height: 360px;
    background: url(/static/img/banners/promo-interesting.jpg) center 100% no-repeat;
    background-size: cover;
    border-radius: 32px;
    padding: 24px;
    .info-block {
      height: 100%;
      max-width: 520px;
      padding: 24px;
      background-color: $gray5;
      color: black;
      border-radius: 24px;
      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 34px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }
      .text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    .top-border {
      padding: 0px 12px 45px 12px;
    }
    .promo-block {
      height: 256px;
      border-radius: 12px;
      padding: 8px;
      align-content: end;
      background: url('/static/img/banners/promo-interesting-mobile.jpg') center top / 100% auto no-repeat;
      background-color: $gray4;
      .info-block {
        height: 152px;
        position: relative;
        padding: 12px;
        border-radius: 12px;
        max-width: inherit;
        top: 0;
        left: 0;
        .title {
          font-size: 22px;
          line-height: 20px;
        }
      }
    }
  }
}

.quiz-categories-wrap {
  padding: 60px 0 30px;
  @include title-main($mbd:30px, $mbm:18px, $fzd:38px, $fzm:28px);
  .list {
    margin-bottom: 20px;
    margin-left: -35px;
  }
  .more-link {
    color: $black;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .quiz-block {
    margin-left: 35px;
    width: calc(25% - 35px);
    .img-block {
      margin-bottom: 15px;
      @include aspect-ratio(14,16,'inner-wrap');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  @media (max-width: $screen-sm) {
    padding: 30px 0;
    .list {
      margin-left: -10px;
    }
    .quiz-block {
      margin-left: 10px;
      width: calc(50% - 10px);
      margin-bottom: 10px;
      .img-block {
        margin-bottom: 10px;
        @include aspect-ratio(16,12,'inner-wrap');
      }
      .title {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
