.stop-weight-title-block {
  .top-border {
    margin-bottom: -25px;
    padding-bottom: 41px;
    padding: 16px 16px 49px 16px;
  }
  .promo-block {
    position: relative;
    height: 360px;
    background: url(/static/img/banners/promo-weight.png) right 100% $white no-repeat;
    border-radius: 32px;
    padding: 24px;
    .info-block {
      height: 100%;
      max-width: 520px;
      padding: 24px;
      background-color: $green;
      color: black;
      border-radius: 24px;
      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 34px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }
      .text {
        font-size: 15px;
        line-height: 22px;
      }
    }

  }
  @media (max-width: $screen-sm) {
    .top-border {
      margin-bottom: -60px;
    }
    .promo-block {
      height: 398px;
      border-radius: 16px;
      padding: 0px;
      //background: url(/static/img/banners/promo-weight.png) 100% $green no-repeat;
      background: url(/static/img/banners/promo-weight.png);
      background-position: bottom;
      background-size: 60%;
      background-repeat: no-repeat;
      background-color: $green;

      .info-block {
        height: unset;
        padding: 12px;
        border-radius: 16px;
        gap: 8px;
      }
    }
  }
}

.body-weight-calc-wrap {
  padding: 30px 0;
  &.second {
    padding: 60px 0 30px;
  }
  .promo-block {
    padding: 16px 72px 38px 30px;
  }
  .title-main {
    margin-bottom: 25px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-size: 48px;
    font-weight: 700;
  }
  .text-main {
    margin-bottom: 60px;
    font-size: 16px;
    color: #626469;
  }
  .title-small,
  .title-small2 {
    font-weight: bold;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
  }
  .title-small {
    margin-bottom: 35px;
    font-size: 24px;
  }
  .title-small2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  a {
    color: $black;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &.btn {
      text-decoration: none;
    }
  }
  .info-block {
    max-width: 340px;
    .btn {
      margin-bottom: 26px;
    }
    .link-other {
      color: $black;
      @include link-line($black);
    }
  }
  .img-block {
    img {
      display: block;
      max-width: 100%;
    }
  }
  .calc-block {
    .img-block {
      filter: grayscale(1);
      opacity: .4;
    }
  }
  .body-weight-calc-results {
    .left-block {
      font-size: 18px;
    }
    .prop {
      color: #979797;
    }
    .stat {
      min-width: 60px;
      color: $text-default;
    }
    .right-block {
      font-size: 18px;
      .img-block {
        margin-right: 18px;
        flex-shrink: 0;
      }
      .title {
        color: #979797;
      }
      .type {
        color: $text-default;
      }
    }
  }
  .recommends-block {
    .prop {
      margin-right: 20px;
    }
  }
  @media (max-width: $screen-sm) {
    padding: 30px 0;
    &.second {
      padding: 30px 0 25px;
    }
    .promo-block {
      padding: 14px 6px 20px;
    }
    .img-block {
      margin-right: 16px;
    }
    .title-main {
      margin-bottom: 6px;
      font-size: 18px;
      &.big {
        margin-bottom: 20px;
        font-size: 28px;
      }
    }
    .text-main {
      margin-bottom: 10px;
      font-size: 13px;
    }
    .title-small {
      font-size: 16px;
    }
    .info-block {
      a {
        font-size: 16px;
        color: $black;
        @include link-line($black);
      }
    }
    a {
      font-size: 13px;
    }
    .body-weight-calc-results {
      .prop {
        margin-right: 10px;
      }
      .prop,
      .stat {
        font-size: 14px;
      }
      .text-main {
        margin-bottom: 20px;
        font-size: 28px;
      }
      .text-main {
        margin-bottom: 20px;
        font-size: 14px;
      }
      .right-block {
        font-size: 14px;
      }
    }
  }
}

.body-type-wrap {
  border-radius: 32px;
  padding: 24px;
  background-color: #f2f3f5;
  @include title-main($mbd:18px, $mbm:38px, $fzd:32px, $fzm:28px);
  .text-main {
    margin-bottom: 18px;
    font-size: 16px;
    color: #626469;
  }
  .collapse {
    padding: 16px 12px;
  }
  .body-type-list-wrap {
    margin: 0;
  }
  .body-type-list {
    margin-left: -16px;
    .body-type-block {
      position: relative;
      border-radius: 32px;
      padding: 24px;
      margin-left: 16px;
      width: calc(25% - 16px);
      height: 317px;
      background-repeat: no-repeat;
      background-position-y: 60px;
      background-color: white;
      background-size: inherit;
      background-position-x: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
      .icon {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 20px;
        color: #3FCBFF;
        &.collapsed {
          color: white;
          &:hover {
            color: #3FCBFF;
          }
        }
        z-index: 2;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .collapse-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 12px;
    background-color: $white;
    .title {
      margin-bottom: 20px;
      font-weight: 700;
    }
    .text {
      color: #626469;
      overflow-y: auto;
    }
  }
  .tab-list {
    margin-left: -8px;
    .tab {
      margin: 0 0 8px 8px;
      position: relative;
      width: calc(50% - 8px);
      //background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
      border-radius: 16px;
      @include aspect-ratio(9, 14, 'inner-wrap');
      .inner-wrap {

      }
      .icon {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
        color: white;
      }
      .sub-title {
        position: absolute;
        top: 8px;
      }
    }
  }
  .tab-content {
    display: none;
    .collapse-block {
      margin-bottom: 10px;
      position: relative;
      top: inherit;
      left: inherit;
      right: inherit;
      bottom: inherit;
      border: 1px solid #8F9399;
      .title {
        margin-right: 30px;
      }
      .icon {
        position: absolute;
        top: 12px;
        right: 10px;
        font-size: 20px;
        color: #3FCBFF;
      }
    }
    &.active {
      display: block;
    }
  }
  @media (max-width: $screen-sm) {
    padding: 12px;
    background-color: white;
    .title {
      padding-top: 12px;
      padding-bottom: 8px;
    }
    .text {
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 12px;
    }
  }
}

.calories-calc-block {
  background-color: black;
  color: white;
  border-radius: 24px;
  .info-block {
    //margin-right: 20px;
    //max-width: 310px;
    padding: 24px;
    .text {
      font-size: 15px;
      max-width: 480px;
    }
    .btn {
      //margin-bottom: 26px;
    }
  }
  @media (max-width: $screen-sm) {
    //.calories-calc-block {
      //padding: 14px 16px 10px;
      border-radius: 16px;
    //}
    .img-block {
      img {
        margin: 0 auto;
        display: block;
        width: 100%;
      }
    }
    .info-block {
      padding: 12px;
      width: 100%;
      max-width: inherit;
      margin: 0;
      .title {
        //font-size: 18px;
      }
      .text {
        //margin-bottom: 10px;
        font-size: 12px;
        line-height: 16px;
      }
      a {
        color: $black;
        //@include link-line($black);
      }
    }
  }
}

.content-list-block-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  .content-list {
    margin-left: -16px;
    overflow-x: auto;
  }
  .content-block {
    margin-left: 16px;
    width: calc(33.3% - 16px);
    flex-shrink: 0;
    color: $text-default;
    padding: 8px;
    border-radius: 16px;
    &.img-block {
      height: 200px;
      //margin-bottom: 10px;
      //@include aspect-ratio(16, 9, 'inner-wrap');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .info-block {
      min-height: 98px;
      padding: 12px;
      border-radius: 12px;
      .img {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
      }
      .author-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
      }
      .author-info {
        font-size: 11px;
        line-height: 10px;
      }
      &.dark {
        color: white;
      }
      &.pink {
        color: white;
      }
      &.blue2 {
        color: white;
      }
      &.quiz {
        min-height: unset;
      }
    }
  }
  .all-link {
    color: $black;
    @include link-line($black);
  }
  @media (max-width: $screen-sm) {
    gap: 8px;
    padding: 12px;
    border-radius: 16px;
    .title {
      font-size: 24px;
      line-height: 22px;
    }
    //padding: 30px 0;
    .content-list {
      gap: 8px;
      //margin-bottom: 30px;
      //margin-left: -20px;
    }
    .content-block {
      //margin-left: 20px;
      width: unset;
      .title {
        //font-size: 14px;
      }
    }
  }
}

.quiz-questions-block-wrap {
  padding: 50px 0 42px;
  color: $text-default;
  .title-block {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-main {
      font-size: 38px;
      font-weight: bold;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
    }
    .category {
      font-size: 24px;
      color: $gray2;
    }
  }
  .title-question {
    margin-bottom: 25px;
    font-size: 24px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: 700;
  }
  .desc {
    margin-bottom: 15px;
    font-size: 24px;
    color: $gray2;
  }
  .quiz-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    .all-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      text-decoration: none;
      &:after {
        display: none;
      }
    }
  }
  .element {
    width: calc(50% - 18px);
    .img-wrapper {
      margin-bottom: 25px;
      border: 2px solid transparent;
      @include aspect-ratio(16, 9, 'img-block');
    }
    .img-block {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .title {
      font-size: 24px;
    }
    &.active {
      .img-wrapper {
        border-color: $black;
        &:after {
          content: '';
          position: absolute;
          top: -20px;
          right: -20px;
          background: url('../img/svg/checkmark.svg') center no-repeat black;
          background-size: contain;
          width: 50px;
          height: 50px;
          border-radius: 40px;
          border: 10px solid black;
        }
      }
    }
  }
  .title-question {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: bold;
  }
  @media (max-width: $screen-sm) {
    padding: 20px 0 30px;
    .title-block {
      align-items: flex-start;
      flex-flow: column nowrap;
      .category {
        margin-bottom: 5px;
        order: 1;
        font-size: 14px;
      }
      .title-main {
        margin-bottom: 10px;
        font-size: 28px;
      }
      .category-img {
        margin-bottom: 20px;
        img {
          display: block;
        }
      }
    }
    .title-question {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }
    .desc {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .element {
      width: calc(50% - 8px);
      .img-wrapper {
        margin-bottom: 5px;
        @include aspect-ratio(9, 12.5, 'img-block');
      }
      .title {
        font-size: 16px;
      }
      &.active {
        .img-wrapper {
          &:after {
            top: -10px;
            right: -10px;
            width: 25px;
            height: 25px;
            border-radius: 40px;
            border: 4px solid black;
          }
        }
      }
    }
    .quiz-question-block {
      margin-bottom: 20px;
      .title {
        margin-bottom: 6px;
        font-size: 16px;
      }
      .element {
        width: calc(50% - 8px);
        .img-wrapper {
          margin-bottom: 6px;
          @include aspect-ratio(9, 13, 'img-block')
        }
      }
    }
  }
}

.calories-calculations-block {
  position: relative;
  //@include title-main($mbd:35px, $mbm:20px, $fzd:38px, $fzm:28px);
  border-radius: 32px;
  padding: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  .form-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .text {
    //margin-bottom: 50px;
    //font-size: 18px;
    //line-height: 25px;
    //color: #626469;
  }
  .img-block {
    //filter: grayscale(1);
    background: url(/static/img/slider1.png);
    background-size: contain;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .title-small {
    margin-bottom: 30px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }
  .text-field {
    width: 100%;
    max-width: 350px;
  }
  .gender {
    .radio:first-child {
      margin-right: 82px;
    }
  }
  .checkbox,
  .radio {
    label {
      color: $text-default;
    }
  }
  .goal {
    .checkbox {
      padding-bottom: 0;
    }
  }
  label {
    color: $gray2;
  }
  .phys {
    margin-bottom: 0;
    .radio {
      padding-bottom: 15px;
      &:last-child {
        padding-bottom: 0;
        label {
          margin-bottom: 0;
        }
      }
    }
  }
  .output-block {
    //margin: 30px 0 60px;
    max-width: 545px;
    padding: 8px;
    border: 1px solid #E8E8ED;
    border-radius: 8px;
    .form-wrap {
      max-width: 545px;
    }
    .form-group {
      max-width: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .radio {
      padding-bottom: 0;
      margin-bottom: 10px;
      label {
        margin-bottom: 0;
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .stats-list {
    //margin-bottom: 35px;
    font-size: 16px;
    .element {
      max-width: 735px;
      margin-bottom: 15px;
    }
    .stat {
      margin: 0 15px 0 0;
      flex-grow: 1;
      color: $text-default;
    }
    .data {
      color: $text-default
    }
  }
  form {
    padding: 0;
    background-color: transparent;
  }
  &.result {
    background-image: none;
  }
  .table-block {
    overflow-x: auto;
    @include smooth-scroll();
    @include scrollbar(auto, 7px, #DADADA, $blue);
  }
  @media (max-width: $screen-sm) {
    .stats-list {
      font-size: 14px;
      line-height: 20px;
    }
    .output-block {
      flex-grow: 1;
    }
    .text {
      //margin-bottom: 12px;
      font-size: 14px;
      line-height: normal;
    }
    .title-small {
      margin-bottom: 20px;
    }
    .output-block {
      //margin-bottom: 30px;
    }
    .inputs-block {
      margin-bottom: 20px;
    }
    .form-group.gender {
      .radio {
        padding-bottom: 0;
        label {
          margin-bottom: 0;
        }
      }
    }
    &:after {
      display: none;
    }
    &.calc-img {
      &:after {
        display: none;
      }
    }
  }
}
