@import "../core/dimentions";
@import "../core/colors";
@import "../core/fonts";
@import "../core/ui-kit/buttons";

.card-video {
  $component-card-video-cover-color: rgba(0, 0, 0, 0.8);

  &:hover {
    text-decoration: none;
  }

  & > .cover {
    position: relative;

    & > .preview {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @media (max-width: $mobile) {
        min-width: 200px;
        min-height: 200px;
      }
    }

    & > .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $component-card-video-cover-color;

      &.date {
        flex-flow: column;

        color: $color-tele2-text-white;
        @include font-tele2-large();
        font-size: 48px;
        line-height: 67px;

        & > .day {
          font-weight: bold;
        }

        & > .month {
        }

        @media (max-width: $mobile) {
          font-size: 28px;
          font-style: normal;
          line-height: 39px;
        }
      }
    }

    & > .button {
      position: absolute;
      right: 9px;
      bottom: 11px;

      @include button-dark();
      @include button-size-xs();

      -webkit-font-smoothing: antialiased;
    }
  }

  & > .title {
    @include font-tele2-medium();
    color: $color-tele2-text-black;
    margin-top: 10px;

    @media (max-width: $mobile) {
      font-size: 16px;
    }
  }

  & > .details {
    @include font-tele2-small();
    color: $color-tele2-text-gray;
    margin-top: 8px;
  }
}