@import "../core/dimentions";
@import "../core/colors";
@import "../core/fonts";
@import "../core/ui-kit/buttons";

$color-back-overlayed: #00c6fa; // цвет серого с оверлеем

.banner-hero {
  position: relative;

  display: block;

  width: 100%;
  min-height: 450px;

  & {
    padding-left: 72px;
    padding-top: 110px;
  }

  z-index: 0;
  background-color: $color-tele2-background-gray;

  &:after {
    display: block;
    content: '';

    background-image: url('/static/img/components/banner-hero/banner-hero-guillotine.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;

    mix-blend-mode: multiply;

    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  & > .art {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  & > .title, & > .text, & > .primary-action {
    display: block;
    z-index: 1;
    max-width: 318px;
    margin-top: 12px;
    padding: 5px;
  }

  & > .title {
    @include font-tele2-huge();
    color: $color-tele2-text-black;
  }

  & > .text {
    @include font-tele2-medium();
    color: $color-tele2-text-black;
  }

  & > .primary-action {
    @include button-clear-dark();
  }

  @media (max-width: $mobile) {
    padding-left: 0;
    padding-top: 0;

    background-color: $color-tele2-background-black;
    text-align:center;
    min-height: auto;

    &:after {
      display: none;
    }

    & > .art {
      display: block;
      position: unset;
      height: 230px;
      width: unset;
      background-position: bottom center;
      background-color: $color-tele2-background-gray;
      background-size: contain;
    }

    & > .title, & > .text, & > .primary-action {
      color: $color-tele2-text-white;
      margin: $dim-basic $dim-basic 0 $dim-basic;
      padding: 0;
      text-align: left;
      max-width: unset;
    }
    
    & > .primary-action {
      @include button-clear-white-small();
      white-space: nowrap;
      margin: $dim-basic;
    }
  }
}