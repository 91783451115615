.banner-new {
  position: relative;
  min-height: 180px;
  overflow: hidden;
  .bg-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90.4deg, rgba(245, 245, 250, 0) 12.92%, #F5F5FA 23.77%, #F5F5FA 97.75%);
  }
  .girl {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .right-block {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.6;
  }
  .content-block {
    position: relative;
    padding: 0 15px 0 296px;
    height: 100%;
    z-index: 1;
    .btn {
      width: 160px;
      padding: 12px 45px;
	  margin-left: 10px;
    }
    >.d-flex {
      height: 100%;
    }
    .title {
      margin-right: 30px;
      font-size: 39px;
      font-weight: 700;
    }
  }
}

.event-block {
  position: relative;
  min-height: 217px;
  .bg-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90.4deg, rgba(245, 245, 250, 0) 12.92%, #F5F5FA 23.77%, #F5F5FA 97.75%);
  }
  .girl {
    position: absolute;
    top: 0;
    left: 0;
    width: 312px;
    height: 100%;
    z-index: -1;
  }
  .right-block {
    position: absolute;
    top: 0;
    right: 0;
  }
  .content-block {
    position: relative;
    padding: 20px 18px 22px 335px;
    z-index: 1;
    .title {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: 700;
      font-size: 25px;
      margin: 0 0 14px 0;
    }
  }
  .right-block {
    position: absolute;
    top: auto;
    right: 42px;
    bottom: 25px;
  }
  .sub-block.first {
    margin-bottom: 20px;
  }
}

@media (max-width: $screen-sm) {
  .banner-new {
    .bg-block {
      position: relative;
      background-color: $gray;
    }
    .content-block {
      padding: 18px 100px 18px 20px;
      .title {
        margin: 0 0 10px 15px;
        font-size: 24px;
        font-weight: 700;
        max-width: 205px;
      }
	  .btn {		
		padding: 12px 35px;		
	  }
    }
  }
  .event-block {
    height: auto;
    .girl {
      position: relative;
      width: 100%;
      height: 120px;
      z-index: 1;
      background-position: center top;
      background-size: 100% auto;
      background-repeat: repeat;
    }
    .content-block {
      padding: 10px 10px 13px;
    }
    .bg-block {
      position: relative;
      background-color: $gray;
    }
    .right-block {
      right: 5px;
      bottom: 15px;
    }
  }
}
