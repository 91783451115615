.shape-page-title-block {
  .top-border {
    margin-bottom: -25px;
    padding-bottom: 41px;
    padding: 16px 16px 49px 16px;
  }
  .promo-block {
    position: relative;
    height: 360px;
    background: url(/static/img/banners/promo-shape.jpg) center 100% no-repeat;
    background-size: cover;
    border-radius: 32px;
    padding: 24px;
    .info-block {
      height: 100%;
      max-width: 520px;
      padding: 24px;
      background-color: $blue2;
      color: black;
      border-radius: 24px;
      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 34px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }
      .text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    .top-border {
      padding: 0px 12px 45px 12px;
    }
    .promo-block {
      height: 340px;
      border-radius: 12px;
      padding: 8px;
      align-content: end;
      background: url('/static/img/banners/promo-shape-mob.jpg') center top / 100% auto no-repeat;
      background-color: $gray4;
      .info-block {
        height: 176px;
        position: relative;
        padding: 12px;
        border-radius: 12px;
        max-width: inherit;
        top: 0;
        left: 0;
        .title {
          font-size: 22px;
          line-height: 20px;
        }
      }
    }
  }
}

.shape-form-top-block {
  margin-bottom: 50px;
  .d-flex {
    background-color: #F5F5F5;
  }
  .text-block {
    padding: 40px 30px;
    font-size: 20px;
    color: #626469;
  }
  .img-block {
    flex-shrink: 0;
    height: 225px;
    width: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  @media (max-width: $screen-sm) {
    margin-bottom: 20px;
    .img-block {
      width: 100%;
      height: 155px;
      background-position: center top;
    }
    .text-block {
      padding: 6px 6px 12px;
      font-size: 14px;
    }
  }
}

.shape-form-calc-wrap {
  &.second {
    padding: 24px;
  }
  .promo-block {
    border-radius: 24px;
    //max-height: 204px;
    background-color: $gray5;
  }
  .title-small,
  .title-small2 {
    font-weight: bold;
  }
  .title-small {
    margin-bottom: 35px;
    font-size: 24px;
  }
  .title-small2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  a {
    color: $black;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &.btn {
      text-decoration: none;
    }
  }
  .info-block {
    display: flex;
    padding: 24px;
    .text {
      font-size: 15px;
      max-width: 480px;
    }
  }
  .img-block {
    img {
      display: block;
      max-width: 100%;
      max-height: 204px;
    }
  }
  .calc-block {
    .img-block {
      filter: grayscale(1);
      opacity: .4;
    }
  }
  .body-weight-calc-results {
    .left-block {
      font-size: 18px;
    }
    .prop {
      color: #979797;
    }
    .stat {
      min-width: 60px;
      color: $text-default;
    }
    .right-block {
      font-size: 18px;
      .img-block {
        margin-right: 18px;
        flex-shrink: 0;
      }
      .title {
        color: #979797;
      }
      .type {
        color: $text-default;
      }
    }
  }
  .recommends-block {
    .prop {
      margin-right: 20px;
    }
  }
  @media (max-width: $screen-sm) {
    &.second {
      padding: 30px 0 25px;
    }
    .promo-block {
      //padding: 16px;
    }
    .img-block {
      width: 100%;
    }
    .title-main {
      margin-bottom: 6px;
      font-size: 18px;
    }
    .text-main {
      margin-bottom: 10px;
      font-size: 13px;
    }
    .title-small {
      font-size: 16px;
    }
    .info-block {
      padding: 12px;
      display: flex;
      //ustify-content: space-between;
      //align-items: flex-end;
      width: 100%;
      .title-main {
        margin-bottom: 0;
        max-width: 155px;
      }
      a {
        font-size: 16px;
        color: $black;
        @include link-line($black);
      }
    }
    a {
      font-size: 13px;
    }
    .body-weight-calc-results {
      .prop {
        margin-right: 10px;
      }
      .prop,
      .stat {
        font-size: 14px;
      }
      .text-main {
        margin-bottom: 20px;
        font-size: 28px;
      }
      .text-main {
        margin-bottom: 20px;
        font-size: 14px;
      }
      .right-block {
        font-size: 14px;
      }
    }
  }
}

.shape-services-block {
  @include title-main($mbd:18px, $mbm:18px, $fzd:38px, $fzm:28px);
  .text-main {
    margin-bottom: 18px;
    font-size: 18px;
    color: #626469;
  }
  .tab-list {
    margin-bottom: 25px;
    border-bottom: none;
    .tab {
      padding: 20px 16px;
      cursor: pointer;
      color: $text-default;
      background: $gray;
      border-radius: 4px;
      .icon-block {
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 36px;
        width: 60px;
        height: 60px;
        color: $white;
        background-color: $text-default;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: calc(100% - 20px);
      }
      .title {
        font-size: 20px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-weight: bold;
      }
      &:hover,
      &.active {
        color: $white;
        background: $text-default;
        .icon-block {
          color: $text-default;
          background-color: #F5F5FA;
        }
      }
    }
  }
  .tab-content-list {
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .form-block-wrap {
    padding: 40px 0 50px;
    color: $white;
    background-color: $text-default;
    .title-main {
      color: $white;
    }
    .text-main {
      color: $white;
      font-size: 13px;
    }
    .add-day-link {
      color: $white;
      .icon {
        margin-right: 10px;
        font-size: 15px;
        transform: rotate(45deg);
      }
    }
    .info {
      font-size: 13px;
      color: #8F9399;
    }
    .btn-block {
      width: auto;
      .btn:first-child {
        margin-right: 36px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    .tab-list {
      .tab {
        margin-bottom: 5px;
      }
    }
    .form-block-wrap {
      padding: 30px 0 40px;
      .text-block {
        margin-bottom: 10px;
      }
      .info {
        text-align: left;
        width: 100%;
      }
      .btn-block {
        justify-content: space-between;
        .btn {
          padding: 0;
          border: none;
          font-weight: normal;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.shape-articles-block {
  padding: 24px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .tab-list {
    //height: 80px;
    //background-color: #F0F1F2;
  }
  .tab-wrap {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .tab-content-list {
      padding: unset;
    }
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .tab {
    padding: 8px 12px;
    border-radius: 12px;
    flex-grow: 0;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    &.active {
      //font-weight: bold;
      color: white;
      background-color: $blue4;
      &:after {
        display: none;
      }
    }
  }

  .article-section-block {
    @include title-main($mbd:18px, $mbm:20px, $fzd:34px, $fzm:24px);
  }
  .article-section-list {
    margin-left: -16px;
    //overflow-x: auto;
    margin-bottom: 16px;
    @include smooth-scroll();
    scroll-snap-type: x proximity;
  }
  .article-block {
    margin-left: 16px;
    flex-shrink: 0;
    width: calc(33.3% - 16px);
    height: 200px;
    border-radius: 12px;
    padding: 12px;
    align-content: end;
    background-size: cover;
    background-repeat: no-repeat;
    &:last-child {
      margin-right: 0;
    }
    .sub-title {
      background-color: $blue3;
      color: white;
      padding: 8px;
      border-radius: 8px;
    }
    .img-block {
      //margin-bottom: 15px;
      @include aspect-ratio(16, 13, 'inner-wrap');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  @media (max-width: $screen-sm) {
    //padding: 30px 0 40px;
    border-radius: 12px;
    padding: 12px;
    gap: 8px;
    .tab-list {
      //margin: 0 -16px 20px;
      //padding: 0 16px;
      overflow-x: auto;
      @include smooth-scroll();
      scroll-snap-type: x proximity;
    }
    .tab {
      //padding: 30px 15px;
    }
    .article-section-list {
      gap: 8px;
      margin-left: 0px;
      margin-bottom: 8px;
    }
    .article-block {
      margin-left: 0;
      width: 100%;
      height: 256px;
      .img-block {
        margin-bottom: 10px;
      }
      .title {
        font-size: 14px;
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

.shape-videos-block {
  padding: 60px 0 70px;
  @include title-main($mbd:38px, $mbm:20px, $fzd:38px, $fzm:28px);
  .shape-videos-list {
    margin-bottom: 64px;
    margin-left: -36px;
    overflow-x: auto;
    @include smooth-scroll();
    scroll-snap-type: x proximity;
  }
  .shape-block {
    margin-left: 36px;
    flex-shrink: 0;
    width: calc(33.3% - 36px);
    &:last-child {
      margin-right: 0;
    }
    .img-block {
      margin-bottom: 10px;
      @include aspect-ratio(16, 9, 'inner-wrap');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .title {
      font-size: 16px;
      color: $text-default;
    }
  }
  .more-link {
    color: $black;
    @include link-line($black);
  }
  @media (max-width: $screen-sm) {
    padding: 30px 0;
    .shape-videos-list {
      margin-left: -20px;
      margin-bottom: 30px;
    }
    .shape-block {
      margin-left: 20px;
      width: 70%;
      .img-block {
        margin-bottom: 8px;
        @include aspect-ratio(1,1, 'inner-wrap');
      }
      .title {
        font-size: 14px;
      }
    }
  }
}

.video-trainings-block {
  padding: 50px 0 90px;
  @include title-main($mbd:18px, $mbm:18px, $fzd:38px, $fzm:28px);
  .text-main {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .tab-list {
    margin-bottom: 25px;
    display: flex;
    border-bottom: 1px solid #D1DBD6;
    .tab {
      position: relative;
      padding: 18px 18px;
      text-align: center;
      flex-grow: 0;
      color: #8F9399;
      cursor: pointer;
      &.active {
        color: $text-default;
        &:after {
          content:'';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 3px;
          background-color: $bgDark;
        }
      }
    }
    &.gray {
      padding: 30px 15px;
      border-bottom: none;
      background-color: #F0F1F2;
      .tab {
        flex-grow: 0;
        padding: 0;
        margin-right: 37px;
        color: #8F9399;
        &.active {
          color: $text-default;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .tab-content-list {
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .video-trainings-section {
    position: relative;
    padding-bottom: 40px;
    .title-block {
      margin-bottom: 18px;
    }
    .title-video {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-size: 24px;
      font-weight: bold;
    }
    .more-link {
      font-size: 16px;
      color: $black;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .videos-list {
      margin-left: -36px;
      overflow-x: auto;
      @include smooth-scroll();
      scroll-snap-type: x proximity;
    }
    .video-block {
      margin-left: 36px;
      flex-shrink: 0;
      width: calc(33.3% - 36px);
      .img-block {
        margin-bottom: 10px;
        @include aspect-ratio(16,9, 'inner-wrap');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      .title {
        font-size: 16px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    padding: 30px 0;
    font-size: 14px;
    .tab-list {
      margin-bottom: 20px;
    }
    .video-trainings-section {
      padding-bottom: 60px;
      .title {
        font-size: 20px;
      }
      .videos-list {
        margin-left: -20px;
        overflow-x: auto;
        @include smooth-scroll();
        scroll-snap-type: x proximity;
      }
      .video-block {
        margin-left: 20px;
        width: 70%;
        .img-block {
          @include aspect-ratio(1,1, 'inner-wrap');
        }
        .title {
          font-size: 14px;
        }
      }
      .more-link {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 20px;
      }
    }
  }
}

.shape-eye-wrap {
  padding: 24px;
  background-color: $gray5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  .tab-list {
    position: relative;
    .tab {
      padding: 8px 12px;
      border-radius: 12px;
      flex-grow: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      &.active {
        background-color: $blue3;
        &:after {
          display: none;
        }
      }
    }
    .tab- {
      //margin-right: 32px;
      flex-grow: 0;
      padding: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .tab-content {
    position: relative;
  }
  .exercise-list {
    gap: 16px;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    //@include smooth-scroll();
  }
  .exercise-block {
    background-repeat: no-repeat;
    min-width: 168px;
    height: 272px;
    flex-shrink: 0;
    &:nth-child(1) {
      background-image: url('/static/img/health-care/exercise-bg1.png');
    }
    &:nth-child(2) {
      background-image: url('/static/img/health-care/exercise-bg2.png');
    }
    &:nth-child(3) {
      background-image: url('/static/img/health-care/exercise-bg3.png');
    }
    &:nth-child(4) {
      background-image: url('/static/img/health-care/exercise-bg4.png');
    }
    &:nth-child(5) {
      background-image: url('/static/img/health-care/exercise-bg5.png');
    }
    &:nth-child(6) {
      background-image: url('/static/img/health-care/exercise-bg6.png');
    }
  }
  .exercise-block-old {
    width: 16.6%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    .img-block {
      margin-bottom: 8px;
    }
    img {
      width: 100%;
    }
    .type {
      margin-bottom: 4px;
      font-size: 12px;
      color: #979797;
    }
    .text {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
    }
    a {
      color: $text-default;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .calendar-list {
    gap: 16px 36px;
    padding: 8px 0 24px;
    display: flex;
    overflow-x: auto;
    @include smooth-scroll;
    @include scrollbar(auto, 5px, #E7E7EE, #3FCBFF);
  }
  .calendar-block {
    position: relative;
    flex-shrink: 0;
    width: 250px;
    padding: 16px 10px;
    background-color: $white;
    .icon {
      position: absolute;
      top: -8px;
      right: -6px;
      cursor: pointer;
    }
    .stat-block {
      margin-bottom: 4px;
    }
    .date {
      margin-bottom: 8px;
      font-size: 14px;
      color: #979797;
    }
    .text {
      margin-bottom: 0;
    }
    .count {
      font-weight: bold;
    }
  }
  @media (max-width: $screen-sm) {
    gap: 16px;
    padding: 12px;
    border-radius: 16px;

    .exercise-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
    .shape-toggle,
    .calendar-toggle {
      font-size: 14px;
      color: $text-default;
      text-decoration: none;
      .icon-cheveron-down {
        display: block;
      }
      .icon-cheveron-up {
        display: none;
      }
      &.active {
        .icon-cheveron-down {
          display: none;
        }
        .icon-cheveron-up {
          display: block;
        }
      }
    }
    .exercise-block {
      min-width: 160px;
      min-height: 186px;
      height: unset;
      background-position: center;
      border-radius: 16px;
      background-color: white;
      .img-block {
        margin: 0;
        width: 140px;
        flex-shrink: 0;
      }
      .info-block {
        padding: 10px 8px;
      }
      &:nth-child(1) {
        background-image: url('/static/img/health-care/exercise-bg1-mob.png');
      }
      &:nth-child(2) {
        background-image: url('/static/img/health-care/exercise-bg2-mob.png');
      }
      &:nth-child(3) {
        background-image: url('/static/img/health-care/exercise-bg3-mob.png');
      }
      &:nth-child(4) {
        background-image: url('/static/img/health-care/exercise-bg4-mob.png');
      }
      &:nth-child(5) {
        background-image: url('/static/img/health-care/exercise-bg5-mob.png');
      }
      &:nth-child(6) {
        background-image: url('/static/img/health-care/exercise-bg6-mob.png');
      }
    }
    .calendar-block {
      &:nth-child(n+3) {
        display: none;
      }
    }
    .calendar-list.active {
      .calendar-block {
        &:nth-child(n+3) {
          display: block;
        }
      }
    }
    .calendar-list {
      flex-flow: column nowrap;
    }
    .calendar-block {
      margin-right: 6px;
      width: auto;
    }
  }
}

.weekend-block-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  .tab-wrap {
    margin: 0;
  }
  .tab-list {
    //margin-bottom: 20px;
    position: relative;
    .tab {
      padding: 8px 12px;
      border-radius: 12px;
      flex-grow: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      &.active {
        //font-weight: bold;
        background-color: $green;
        &:after {
          display: none;
        }
      }
    }

    .tab- {
      //margin-right: 32px;
      flex-grow: 0;
      padding: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .specialist-list {
    margin-left: -35px;
    overflow-y: auto;
    .specialist-block {
      margin-left: 35px;
      width: calc(31.3% - 35px);
      flex-grow: 1;
      max-width: calc(33.3% - 35px);;
      flex-shrink: 0;
      .img-block {
        margin-bottom: 10px;
        @include aspect-ratio(16, 9, 'inner-wrap');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
      .date {
        font-size: 14px;
        color: #979797;
      }
      .title {
        font-size: 16px;
        font-weight: normal;
        color: $text-default;
      }
    }
  }
  @media (max-width: $screen-sm) {
    gap: 16px;
    padding: 12px;
    border-radius: 16px;
  }
}


.video-training-wrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 32px;
  .tab-wrap {
    margin: 0;
  }
  .tab-list {
    //margin-bottom: 20px;
    position: relative;
    .tab {
      padding: 8px 12px;
      border-radius: 12px;
      flex-grow: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      &.active {
        background-color: $blue3;
        &:after {
          display: none;
        }
      }
      &:nth-child(n+2) {
        &.active {
          background-color: $pink;
          color: white;
          &:after {
            display: none;
          }
        }
      }
    }
    .tab- {
      //margin-right: 32px;
      flex-grow: 0;
      padding: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .video-training-content-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  @media (max-width: $screen-sm) {
    gap: 16px;
    padding: 12px;
    border-radius: 16px;
  }
}
