.wrapper.special {
  height: 100%;
  .content {
    flex: 1 1 auto;
    min-height: 48px;
  }
  footer {
    position: sticky;
    bottom: 0;
  }
}
.service-chat-wrap {
  position: relative;
  height: 100%;
  .chats-col {
    padding: 0 0 70px 70px;
    flex-grow: 1;
    max-width: 531px;
    border-right: 1px solid #E8E8ED;
  }
  .btn-toggle {
    padding: 18px 23px 18px 20px;
    background-color: $gray;
    border-bottom: 1px solid #E8E8ED;
    cursor: pointer;
    .text {
      font-size: 16px;
      color: rgba(31, 34, 41, 0.7);
    }
    .toggle-icon {
      flex-shrink: 0;
      font-size: 24px;
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      &:before {
        content: "\e906";
      }
    }
    &.active {
      .toggle-icon {
        &:before {
          content: "\e903";
        }
      }
    }
  }
  .toggle-block {
    height: 100%;
    overflow-y: auto;
  }
  .chat-list {
    .chat-block {
      padding: 16px 23px 16px 20px;
      border-bottom: 1px solid #E8E8ED;
      .spec-name {
        color: rgba(31, 34, 41, 0.7);
      }
      .unread-messages-count {
        margin-left: 15px;
        width: 24px;
        height: 24px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 50%;
        background-color: $blue;
      }
      &.active,
      &:hover {
        background-color: $gray;
      }
    }
  }
  .msg-list-col {
    flex-grow: 1;
  }
  .msg-inner {
    padding: 14px 70px 50px 40px;
    font-size: 14px;
    overflow-y: auto;
  }
  .msg-start-date {
    margin-bottom: 25px;
    color: #8F9399;
  }
  .msg-block {
    margin-bottom: 6px;
    .avatar-block {
      margin-bottom: 14px;
      margin-right: 10px;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $gray;
      &.default {
        background-size: auto;
      }
    }
    .bubble {
      padding: 15px 16px;
      border-radius: 15px 15px 15px 0;
      background-color: $gray;
    }
    .msg-date {
      font-size: 10px;
      color: #8F9399;
    }
    &.author {
      .info-block {
        order: 1;
      }
      .avatar-block {
        order: 2;
        margin-left: 10px;
        margin-right: 0;
      }
      .bubble {
        color: $white;
        border-radius: 15px 15px 0 15px;
        background-color: $blue;
      }
      .msg-date {
        text-align: right;
      }
    }
  }
  .chat-input-block {
    border-top: 1px solid #DADADA;
    position: relative;
    background-color: $gray;
    input {
      background-color: transparent;
      padding: 0 60px 0 30px;
      width: 100%;
      height: 46px;
      border: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .send {
      position: absolute;
      top: 13px;
      right: 30px;
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }
}

@media (max-width: $screen-sm) {
  .service-chat-wrap {
    position: unset;
    max-height: calc(100vh - 60px);
    .chats-col {
      padding: 0;
      max-width: inherit;
      border-right: none;
    }
    .toggle-block {
      display: none;
      position: absolute;
      top: 61px;
      height: calc(100% - 61px);
      width: 100%;
      z-index: 3;
      background: #fff;
      &.active {
        display: block;
      }
    }
    .msg-list-col {
      height: calc(100% - 61px);
    }
    .msg-inner {
      padding: 22px 10px 47px;
    }
    .chat-input-block {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
    }
  }
  .wrapper.special {
    position: relative;
    height: auto;
    .content {
      position: relative;
    }
    footer {
      position: relative;
      bottom: inherit;
    }
  }
}
