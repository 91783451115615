@import "../core/dimentions";

.promo-grid-row {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 1em;

  @media (max-width: $mobile) {
    display: flex;
    flex-flow: row;
    overflow-y: scroll;

    padding-left: $dim-page-padding-mobile;
    margin-left: -$dim-page-padding-mobile;
    padding-right: $dim-page-padding-mobile;
    margin-right: -$dim-page-padding-mobile;
  }
}