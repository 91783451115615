$white: #fff;
$black: #000;

$text-default: #1F2229;
$text-default2: #14161A;
$header-link: #626569;
$gray: #F5F5FA;
$gray2: #8F9399;
$gray3: #F2F3F5;
$gray4: #B6B6B6;
$gray5: #F2F2F2;
$gray6: #8A8F97;
$gray7: #808080;


$blue: #3FCBFF;
$blue2: #00BFFF;
$blue3: #396BFF;
$blue4:#0000FF;

$errorText:#D81B3B;
$errorText2: #D72F4B;
$bgDark: #141416;
$textGray: #7a7a7a;
$screen-sm: 768px;
$screen-xs: 576px;
$green: #A7FC00;
$pink: #FF3495;
