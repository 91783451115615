.stream-block {
  position: relative;
  height: calc(100vh - 76px);
  &.shapes {
    height: calc(100vh - 135px);
    min-height: 350px;
    overflow: hidden;
  }
}

.figure-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:
      url(../img/svg/shape-form.svg) center / contain no-repeat,
      linear-gradient(180deg, rgba(31, 34, 41, 0) 0.17%, rgba(31, 34, 41, 0.56) 0.18%, #4C4C4C 100%);
  &.no-shadow {
    background: url(../img/svg/shape-form.svg) center / contain no-repeat;
  }
}

.status-block {
  position: absolute;
  top: 34px;
  left: 70px;
  z-index: 1;
  .online {
    display: inline-block;
    padding: 2px 10px;
    font-size: 13px;
    line-height: 18px;
    color: $text-default;
    background-color: $blue;
    border-radius: 10px;
  }
  .status-info {
    .author {
      color: #E3E3E3;
      font-size: 11px;
    }
    .title {
      font-size: 13px;
      font-weight: bold;
      color: $white;
    }
  }
}

.stream-bot-block {
  position: absolute;
  left: 70px;
  bottom: 22px;
  color: $white;
  z-index: 1;
  .streamer {
    color: #DADADA;
    font-size: 12px;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .contacts {
    font-size: 11px;
    span {
      color: $gray2;
    }
  }
  .stream-start {
    margin-bottom: 33px;
    font-weight: bold;
    .title {
      font-size: 18px;
      color: #dadada;
    }
    .date {
      font-size: 18px;
      color: $blue;
    }
  }
  .feedback-block {
    font-size: 13px;
    .title {
      color: #dadada;
      font-weight: normal;
    }
    .email {
      font-weight: bold;
      color: $white;
    }
  }
}

.player-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bgDark;
  .player-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }
  .fade-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(31, 34, 41, 0.38) 0.17%, rgba(31, 34, 41, 0.56) 55.45%, rgba(31, 34, 41, 0.97) 100%);
    z-index: 1;
  }
  &.online {
    .fade-bg {
      top: auto;
      height: 290px;
      background: linear-gradient(180deg, rgba(31, 34, 41, 0) 0.17%, rgba(31, 34, 41, 0.7) 60.95%, #1F2229 100%);
    }
  }
}

.stream-events-wrap {
  padding-top: 20px;
  .title-block {
    margin-bottom: 20px;
    color: $text-default;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: 700;
    .title {
      font-size: 28px;
    }
    .date {
      font-size: 22px;
    }
  }
  .title-main {
    margin-bottom: 40px;
    font-size: 38px;
    color: $text-default;
    font-weight: 700;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
  }
  .img-block {
    margin-bottom: 20px;
    img {
      display: block;
      width: 100%;
    }
    .title {
      padding: 10px 10px 14px;
      color: $text-default;
      font-size: 16px;
      font-weight: bold;
      background-color: $gray;
    }
  }

  .text {
    margin-bottom: 20px;
    font-size: 14px;
    color: #626469;
    span {
      color: $text-default;
    }
  }
  .tab-list {
    margin-bottom: 18px;
  }
}
.stream-event-list {
  margin-left: -36px;
}
.stream-event-wrap {
  margin-left: 36px;
  margin-bottom: 10px;
  flex-shrink: 0;
  width: calc(33.3% - 36px);
}
.stream-event-block {
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(31, 34, 41, 0) 29.95%, rgba(31, 34, 41, .5) 57.4%);
  }
  .img-block {
    margin-bottom: 0;
    img {
      display: block;
      width: 100%;
    }
  }
  .stream-event-flex {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    .info-block {
      .stream {
        font-size: 12px;
        color: #DADADA;
      }
      .title-name {
        font-size: 14px;
        color: $white;
      }
    }
    .btn {
      padding: 2px 25px;
      font-size: 13px;
      color: $white;
      font-weight: bold;
      border-radius: 30px;
      background-color: $blue;
      border-color: $blue;
    }
  }
}
.stream-date-wrap {
  margin-bottom: 27px;
  font-size: 12px;
  .date {
    margin-bottom: 4px;
    color: $blue;
    span {
      color: #979797;
    }
  }
  .title {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: bold;
  }
  .info {
    color: $text-default;
    span {
      color: #8F9399;
    }
  }
}
.stream-events-wrapper {
  .more-link {
    margin-bottom: 40px;
    color: $text-default;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .widget-box.events {
    color: #626469;
    .top-bg-wrap {
      height: 251px;
      .top-bg {
        &:before {
          content: '';
          display: none;
        }
      }
    }
  }
}

.stream-list {
  margin-bottom: 30px;
  margin-left: -36px;
  .stream-block {
    margin: 0 0 30px 36px;
    flex-shrink: 0;
    width: calc(33.3% - 36px);
    height: auto;
    .btn {
      padding: 2px 25px;
      font-size: 13px;
      color: #fff;
      font-weight: 700;
      border-radius: 30px;
      background-color: #3fcbff;
      border-color: #3fcbff;
      position: absolute;
      right: 20px;
      bottom: 35px;
    }
    .preview-block {
      position: relative;
      margin-bottom: 14px;
      img {
        display: block;
        width: 100%;
      }
    }
    .info-block {
      .date {
        margin-bottom: 5px;
        font-size: 13px;
        color: $gray2;
      }
      .title {
        margin-bottom: 6px;
        color: $text-default;
        font-size: 14px;
      }
      .text {
        margin-bottom: 32px;
        font-size: 16px;
        color: $text-default;
      }
      .streamer-block {
        display: inline-flex;
        align-items: center;
        color: $gray2;
        padding-right: 10px;
        border-radius: 10px;
        background-color: #EFEFEF;
        vertical-align: text-bottom;
        [class^="icon-"] {
          margin-right: 10px;
          font-size: 24px;
        }
        .name {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .stream-block {
    height: calc(100vh - 60px);
    &.shapes {
      height: calc(100vh - 97px);
    }
  }
  .stream-list {
    margin: 0 0 0 0;
    .stream-block {
      margin: 0 0 20px 0;
      width: 100%;
      .info-block {
        .text {
          margin-bottom: 10px;
        }
      }
    }
  }
  .status-block {
    top: 10px;
    left: 10px;
  }
  .stream-bot-block {
    left: 10px;
    bottom: 22px;
  }
  .widget-box.events {
    font-size: 14px;
    .special-block {
      margin: 100px -16px 10px;
    }
  }
  .stream-events-wrap {
    .title-main {
      margin-bottom: 18px;
      font-size: 22px;
    }
  }
  .stream-event-list {
    margin-left: 0;
  }
  .stream-event-wrap {
    margin-left: 0;
    width: 100%;
  }
}

.shape-top-block,
.stream-shape-bot {
  position: absolute;
  left: 32px;
  z-index: 1;
  &.with-bg {
    padding: 16px;
    background: rgba(31, 34, 41, 0.8);
    border-radius: 4px;
  }
}
.shape-top-block {
  top: 32px;
  img {
    margin-right: 10px;
    width: 46px;
  }
  .text {
    font-size: 14px;
    color: #8F9399;
  }
  @media (max-width: $screen-sm) {
    top: 8px;
    left: 8px;
    &.with-bg {
      padding: 0;
      background-color: transparent;
    }
  }
}

.stream-shape-bot {
  bottom: 32px;
  .top {
    margin-bottom: 32px;
  }
  .text {
    margin-right: 12px;
    color: #979797;
  }
  .count {
    color: $white;
    font-size: 72px;
    line-height: 72px;
    font-weight: bold;
  }
  @media (max-width: $screen-sm) {
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $text-default;
    &.with-bg {
      padding: 0;
      border-radius: 0;
      background-color: $text-default;
    }
    .name {
      padding: 4px 10px;
      font-size: 12px;
      color: #8F9399;
      background-color: $white;
    }
    .top-wrap {
      padding: 12px 10px;
    }
    .top {
      margin-bottom: 0;
      .text {
        font-size: 12px;
      }
      .count {
        font-size: 42px;
        line-height: 42px;
      }
    }
  }
}
