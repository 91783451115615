.main-page-wrap {
  .top-border {
    margin-bottom: -25px;
    padding-bottom: 41px;
    padding: 16px 0px 49px 0px;
  }
  .promo-block {
    position: relative;
    height: 360px;
    background: url(/static/img/banners/promo-index.jpg) center 100% no-repeat;
    border-radius: 32px;
    padding: 24px;
    .info-block {
      height: 100%;
      max-width: 520px;
      padding: 24px;
      background-color: black;
      color: white;
      border-radius: 24px;
      .title {
        //margin-bottom: 18px;
        //font-family: 'Standard Stencil Bold', sans-serif;
        font-size: 38px;
        font-weight: 800;
        line-height: 34px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }
      .text {
        font-size: 15px;
        line-height: 22px;
        //margin-bottom: 18px;
      }
    }
  }

  .categories-list-wrap {
    .cat-list {
      margin-bottom: 32px;
      gap: 38px;
    }
    .cat-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      color: $black;
      font-size: 22px;
      width: calc(25% - 15px);
      text-decoration: none;
      background-color: #F5F5F5;
    }
    @media(max-width: $screen-sm) {
      .cat-list {
        gap: 18px;
      }
      .cat-block {
        width: 100%;
      }
    }
  }

  .programs-wrap {
    //background-color: white;
    //border-radius: 32px;
    //padding: 60px 0;
    @include title-main();
    .programs-list {
      .programs {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 50%;
        padding: 24px;
        //padding: 35px 18px 30px;
        //border: 1px solid #D1D6DB;
        border-radius: 32px;
        background-color: white;
        .img-block {
          //margin: 0 auto 25px;
          flex-shrink: 0;
          width: 48px;
          height: 48px;
          border-radius: 16px;
          background-color: $gray3;
          background-position: center;
          background-repeat: no-repeat;
        }
        .title {
          margin-bottom: 18px;
          //font-family: 'T2 Halvar Breitschrift', sans-serif;
          //font-size: 20px;
          //font-weight: bold;
        }
        ul {
          padding-inline-start: 20px;
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }

  .banner-new {
    margin-bottom: 60px;
    padding: 0 72px;
    height: 180px;
    .container-fluid {
      position: relative;
      width: auto;
      height: 100%;
      justify-content: center;
      background: url('/static/img/banner-new.png') left center #F6F6FB no-repeat;
      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        right: 0;
        width: 240px;
        background: url('/static/img/banner-new2.png') center / 100% auto no-repeat;
      }
    }
    .title {
      margin-right: 30px;
      font-size: 39px;
      font-weight: bold;
    }
    .text {
      max-width: 365px;
    }
    &.podcast {
      .container-fluid {
        padding-left: 30px;
        justify-content: flex-start;
        background: $blue no-repeat;
      }
      .content-part {
        width: calc(100% - 200px);
      }
    }
    &.health-eye {
      height: auto;
      min-height: 130px;
      .content-part {
        width: 100%;
      }
      .title {
        text-transform: uppercase;
      }
      .text {
        max-width: inherit;
      }
      .container-fluid {
        color: $white;
        padding: 16px 24px;
        justify-content: flex-start;
        background: $text-default no-repeat;
        img {
          margin-right: 24px;
        }
        &:after {
          display: none;
        }
      }
    }
  }

  .tab-list {
    display: flex;
    border-bottom: 1px solid #D1DBD6;
    .tab {
      position: relative;
      padding: 8px 12px;
      text-align: center;
      flex-grow: 1;
      color: #8F9399;
      cursor: pointer;
      &.active {
        color: $text-default;
        &:after {
          content:'';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 3px;
          background-color: $bgDark;
        }
      }
    }
    &.gray {
      padding: 30px 15px;
      border-bottom: none;
      background-color: #F0F1F2;
      .tab {
        flex-grow: 0;
        padding: 0;
        margin-right: 37px;
        color: #8F9399;
        &.active {
          color: $text-default;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .tab-content-list {
    .tab-content {
      display: none;
      &.active {
        display: block;
      }
    }
  }



  .specialist-block-wrap {
    padding: 60px 0;
    @include title-main($mbd:18px);
    .text {
      font-size: 18px;
      color: $text-default;
      &.gray {
        color: #626469;
        background-color: transparent;
        margin-bottom: 30px;
      }
    }
    .spec-list {
      margin-left: -15px;
      overflow-x: auto;
      .spec-block {
        margin-bottom: 25px;
        margin-left: 15px;
        position: relative;
        flex-shrink: 0;
        width: calc(20% - 15px);
        min-width: 205px;
        min-height: 418px;
        padding: 15px 16px 22px;
        border: 1px solid #D1D6DB;
        .top {
          width: 100%;
          text-align: center;
        }
        .special-event {
          position: absolute;
          top: 55px;
          right: 0;
          padding-right: 2px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 75px;
          height: 30px;
          background: url('/static/img/svg/special-ribbon.svg') no-repeat;
          font-family: "T2 Halvar Breitschrift", sans-serif;
          font-size: 9px;
          font-weight: 700;
          color: $white;
          text-align: center;
        }
        .day-block {
          margin-bottom: 20px;
          padding: 0 10px;
          height: 25px;
          line-height: 24px;
          color: $text-default;
          background-color: #DADADA;
        }
        .avatar-block {
          margin: 0 25px 25px;
          overflow: hidden;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @include aspect-ratio(1,1, 'inner-wrap');
        }
        .name {
          margin-bottom: 6px;
          font-size: 18px;
          font-weight: 700;
          font-family: 'T2 Halvar Breitschrift', sans-serif;
        }
        .prof {
          margin-bottom: 15px;
          font-size: 15px;
          color: #8F9399;
        }
        .btn {
          display: none;
          padding: 0 12px;
          max-width: 175px;
          height: 50px;
          img {
            margin-right: 2px;
            width: 35px;
          }
        }
        &.active,
        &:hover {
          .day-block {
            color: $white;
            background-color: $text-default;
          }
          .btn {
            display: block;
          }
        }
      }
    }
  }

  .discounts-block-wrap {
    padding: 24px;
    border-radius: 24px;
    background-color: $green;
    .discount-block {
      position: relative;
      padding: 24px;
      border-radius: 24px;
      background-color: white;
      height: 160px;
      width: 100%;
    }

    @include title-main();
    .tile-list {
      display: flex;
      margin-bottom: 40px;
      overflow-x: auto;
      @include smooth-scroll();
      scroll-snap-type: x proximity;
      .tile {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        min-width: 205px;
        justify-content: space-between;
        padding: 30px 30px 35px;
        width: 25%;
        color: $text-default;
        background-color: #F5F5FA;
        border-right: 1px solid #E8EDEB;
        scroll-snap-align: start;
        .img-block {
          margin-bottom: 15px;
          overflow: hidden;
          border-radius: 50%;
          @include aspect-ratio(1, 1, 'inner-block');
          .inner-block {
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .title {
          margin-bottom: 30px;
          text-align: center;
          font-size: 22px;
          line-height: 31px;
          font-weight: inherit;
        }
        .btn {
          width: 100%;
          max-width: 205px;
        }
        .all-cover-link {
          z-index: 1;
          @include all-cover-link();
        }
        &:last-child {
          border: none;
        }
      }
    }
    .all-link {
      font-size: 16px;
      line-height: 22px;
      color: $black;
      @include link-line($black);
    }
  }
}

.stream-events-block-wrap {
  padding: 50px 0;
  @include title-main();
  .all-link {
    color: $black;
    @include link-line($black);
  }
  .stream-event-list {
    margin: 0 0 40px -36px;
  }
  .stream-block {
    margin: 0 0 20px 36px;
    width: calc(33.3% - 36px);
    height: auto;
    .img-block {
      margin-bottom: 10px;
      @include aspect-ratio(16, 9, 'inner-wrap');
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
    }
    .inner-wrap {
      position: absolute;
      padding: 12px 6px;
      font-size: 14px;
      background: linear-gradient(180deg,rgba(31,34,41,0) 29.95%,rgba(31,34,41,.277317) 43%,rgba(31,34,41,.89) 100%);
    }
    .name {
      color: $white;
    }
    .profession {
      color: #DADADA;
    }
    .btn {
      padding: 6px 20px;
    }
    .gray {
      color: #979797;
      background-color: transparent;
    }
    .text {
      margin-bottom: 18px;
      font-size: 16px;
      &.small {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
    .bot-block {
      .text {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .stream-events-block-wrap {
    padding: 20px 0;
    .title-main {
      margin-bottom: 20px;
      font-size: 28px;
    }
    .stream-event-list {
      margin: 0 0 10px 0;
    }
    .stream-block {
      margin-left: 0;
      width: 100%;
      .text {
        margin-bottom: 10px;
      }
    }
  }
  .main-page-wrap {
    .top-border {
      padding: 0px 12px 45px 12px;
    }
    .promo-block {
      height: 264px;
      border-radius: 12px;
      padding: 8px;
      align-content: end;
      background: url('/static/img/banners/promo-index-mobile.jpg') center top / 100% auto no-repeat;
      .bg-block {
        position: relative;
        height: 230px;
        background: url('/static/img/promo-podcast-mobile.png') center top / 100% auto $gray no-repeat;
      }
      .info-block {
        height: 104px;
        position: relative;
        padding: 12px;
        border-radius: 12px;
        max-width: inherit;
        top: 0;
        left: 0;
        color: $white;
        background-color: $text-default;
        .title {
          font-size: 22px;
          line-height: 20px;
        }
        .text {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .programs-wrap {
      padding: 0;
      .programs-list {
        .programs {
          //margin-bottom: 10px;
          width: 100%;
          padding: 12px;
          //border: 1px solid #D1D6DB;
          border-radius: 16px;
          gap: 12px;
          .img-block {
            margin: 0 7px 0 0;
            width: 62px;
            height: 62px;
            background-size: calc(100% - 20px) calc(100% - 20px);
          }
          .title {
            margin-bottom: 5px;
            font-size: 18px;
          }
          .text {
            font-size: 14px;
          }
        }
      }
    }
    .banner-new {
      margin-bottom: 10px;
      padding: 0;
      height: 180px;
      .container-fluid {
        background: #F6F6FB;
        &:after {
          width: 140px;
        }
      }
      .title {
        margin: 0 0 15px;
        font-size: 29px;
      }
      &.podcast {
        height: auto;
        .container-fluid {
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: start;
          background: $blue;
          .title {
            width: 200px;
          }
          &:after {
            right: -128px;
            bottom: 0;
            width: 290px;
            height: 100%;
            background: url(/static/img/banner-new2_mobile.png) 0/ auto 82% no-repeat;
          }
        }
      }
      &.health-eye {
        height: auto;
        &:after {
          content: '';
          position: absolute;
          top: 6px;
          bottom: 6px;
          left: 20px;
          right: 20px;
          background: url('../img/banners/eye-mobile.svg') center / auto no-repeat;
        }
        .title {
          margin-bottom: 5px;
          font-size: 28px;
        }
        .text {
          margin-bottom: 14px;
          font-size: 14px;
        }
      }
    }
    .specialist-list {
      margin-left: 0;
      .specialist-block {
        margin-left: 0;
        width: 100%;
        max-width: initial;
      }
    }
    .weekend-block-wrap {
      padding: 20px 0;
      .text-main {
        margin-bottom: 20px;
        font-size: 14px;
      }
      .tab-list {
        margin-bottom: 50px;
        .tab {
          margin-right: 22px;
          padding: 0 2px 8px;
        }
      }
      .filter-block {
        right: inherit;
        top: 32px;
        left: 16px;
        z-index: 1;
      }
      .specialist-list {
        margin-left: 0;
        .specialist-block {
          margin-left: 0;
          width: 100%;
          max-width: none;
          .title {
            margin-bottom: 20px;
          }
        }
      }
    }
    .specialist-block-wrap {
      padding: 20px 0;
      .text {
        font-size: 14px;
      }
      .spec-list {
        margin: 0 0 5px 0;
        .spec-block {
          margin: 0 0 10px 0;
          padding: 35px 10px 20px;
          width: 100%;
          min-height: auto;
          border-radius: 4px;
          .special-event {
            top: -35px;
            right: -10px;
            z-index: 1;
          }
        }
        .prof {
          margin-bottom: 0;
        }
        .day-block {
          position: absolute;
          top: -35px;
          left: -10px;
          font-size: 13px;
        }
        .dot {
          position: absolute;
          top: -28px;
          right: -3px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $blue;
        }
        .top {
          margin-bottom: 16px;
          position: relative;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          .avatar-block {
            margin: 0 0 0 15px;
            flex-shrink: 0;
            order: 2;
            width: 85px;
            height: 85px;
          }
          .info-block {
            text-align: left;
            order: 1;
            flex-grow: 1;
          }
        }
      }
    }

    .discounts-block-wrap {
      padding: 12px;
      border-radius: 16px;
      .discount-block {
        padding: 12px;
        border-radius: 16px;
        height: 186px;
      }
      .tile-list {
        margin: 0 -16px 30px;
        display: flex;
        flex-flow: column nowrap;
        .tile {
          border-top: 1px solid #D1DBD6;
          border-right: none;
          padding: 14px 10px;
          width: 100%;
          &:first-child {
            border-top: none;
          }
          &:last-child {
            border-top: 1px solid #D1DBD6;
          }
          .img-block {
            margin: 0 10px 0 0;
            width: 77px;
            flex-shrink: 0;
          }
          .top-block {
            position: relative;
            padding-right: 18px;
            display: flex;
            align-items: center;
            &:after {
              content:'';
              position: absolute;
              right: 0;
              width: 7px;
              height: 13px;
              background: url('../img/svg/more.svg') no-repeat;
            }
          }
          .title {
            margin: 0;
            font-size: 14px;
            line-height: normal;
            font-weight: normal;
            text-align: left;
          }
          .bot-block {
            display: none;
          }
        }
      }

    }
  }
}
