.health-page-wrap {
  .sleep-list {
    margin: 0 0 -45px -36px;
    overflow-x: auto;
    @include smooth-scroll();
    padding-bottom: 40px;
    scroll-snap-type: x proximity;
    .element {
      margin-bottom: 45px;
      scroll-snap-align: start;
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      margin-left: 36px;
      width: calc(25% - 36px);
      min-width: 224px;
      .inner-block {
        margin-bottom: 20px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include aspect-ratio(1,1, 'inner-block');
      }
      .title {
        margin-bottom: 32px;
        font-size: 22px;
        font-weight: bold;
      }
      .all-cover-link {
        @include all-cover-link();
        z-index: 1;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &.wrap-big {
      flex-flow: row wrap;
    }
  }
}

.test-block {
  .test-element {
    margin-bottom: 10px;
    .title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      color: $text-default;
    }
    .radio {
      padding-bottom: 15px;
      label {
        color: #8F9399;
        margin-bottom: 0;
      }

    }
  }
  .result-wrap {
    .result-col {
      width: calc(50% - 15px);
      min-height: 430px;
      >.d-flex {
        height: 100%;
      }
    }
    .top-block {
      .result-title {
        margin-bottom: 15px;
        font-family: 'T2 Halvar Breitschrift', sans-serif;
        font-weight: bold;
        font-size: 24px;
        color: $black;
      }
      .result-desc {
        margin-bottom: 30px;
        font-size: 18px;
        color: $text-default;
        .title {
          margin-bottom: 5px;
        }
        p {
          color: #8F9399;
        }
        a {
          font-family: 'T2 Halvar Breitschrift', sans-serif;
          color: $text-default;
          @include link-line($text-default);
        }
      }
    }
    .img-block {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: grayscale(100%);
    }
  }
}

.text-content {
  .sep-block {
    margin: 0 20px;
    flex-shrink: 0;
    width: 1px;
    background-color:  #A5A7A9;
  }
  .text-col {
    .title {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.vaccinations-wrap {
  .table-block {
    margin-bottom: 40px;
    padding-bottom: 34px;
    overflow-x: auto;
    @include scrollbar(auto, 7px, #DADADA, $blue);
  }
  .table-legend {
    .color-list {
      margin-bottom: 8px;
      .element {
        margin: 0 40px 32px 0;
        .color-block {
          width: 62px;
          height: 62px;
        }
        font-size: 16px;
        .color-block {
          margin-right: 10px;
        }
        .text {
          max-width: 260px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .codes {
      font-size: 13px;
      .text {
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .health-page-wrap {
    .sleep-list {
      margin-left: 0;
      .element {
        margin: 0 20px 15px 0;
        width: calc(33.3% - 32px);
        .title {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
      &.wrap-big {
        flex-flow: row nowrap;
      }
    }
    .widget-form-block {
      .title {
        font-size: 38px;
      }
      .text {
        font-size: 13px;
        line-height: normal;
        &.small {
          font-size: 12px;
        }
      }
      .custom-select-block,
      .custom-input-block {
        margin-bottom: 10px;
        min-width: inherit;
      }
    }
  }
  .test-block {
    .result-wrap {
      .top-block {
        .result-title {
          margin-bottom: 15px;
          font-family: 'T2 Halvar Breitschrift', sans-serif;
          font-weight: bold;
          font-size: 20px;
          color: $black;
        }
      }
      .result-col {
        width: 100%;
        min-height: 275px;
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
          order: 1;
        }
      }
      .img-block {
        height: 275px;
      }
    }
  }
  .test-block-mobile {
    font-size: 14px;
    .count {
      margin: -8px 0 10px;
      color: #8F9399;
    }
    .question-img {
      margin-bottom: 15px;
    }
    .test-element {
      margin-bottom: 9px;
      .title {
        margin-bottom: 15px;
        color: $text-default;
        font-size: 16px;
        font-weight: bold;
      }
      .radio {
        padding-bottom: 15px;
        label {
          margin-bottom: 0;
        }
      }
    }
    .control-block {
      a {
        display: flex;
        align-items: center;
        color: $text-default;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .health-page-wrap {
    .sleep-list {
      .element {
        width: calc(33.3% - 32px);
        .title {
          font-size: 14px;
          font-weight: normal;
        }
        .btn {
          display: none;
        }
      }
    }
    .widget-form-block {
      .custom-select-block,
      .custom-input-block {
        width: 100%;
      }
      .btn-block {
        width: 100%;
      }
      .title {
        font-size: 28px;
      }
    }
  }
  .test-block {
    .result-wrap {
      .result-col {
        width: 100%;
        min-height: 185px;
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
          order: 1;
        }
      }
      .top-block {
        .result-desc {
          font-size: 16px;
          color: $text-default;
        }
      }
      .img-block {
        height: 185px;

      }
    }
  }
  .vaccinations-wrap {
    .table-legend {
      .color-list {
        margin-bottom: 8px;
        .element {
          margin: 0 40px 32px 0;
          .color-block {
            width: 32px;
            height: 32px;
          }
          font-size: 14px;
        }
      }
    }
  }
}
