@import "../core/colors";
@import "../core/fonts";
@import "../core/dimentions";

.page-content {

  .page-block,
  .page-block > .container-fluid {

    padding-top: 24px;

    &:nth-child(even) {
      background-color: $gray;
    }

    & > .title {
      @include font-tele2-title();
    }

    & > .details {
      @include font-tele2-large();
      color: $color-tele2-text-gray;
      margin-top: 21px;
    }

    & > .content {
      margin-top: 21px;
      padding-bottom: 36px;
    }
  }
}
