footer {
  position: relative;
  padding: 52px 0 55px;
  font-size: 14px;
  color: $white;
  background-color: $text-default;
  margin-top: -24px;
  z-index: -1;
}

.copyright {
  margin-bottom: 16px;
  flex-shrink: 0;
  font-size: 14px;
  color: $white;
}

.footer-menu {
  flex-flow: row nowrap !important;
  a {
    white-space: nowrap;
    margin: 0 32px 20px 0;
    color: $white;
  }
}

.cookies-block {
  color: #8F9399;
  min-width: 480px;
  line-height: 20px;
  a {
    color: #8F9399;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (max-width: $screen-sm) {
  footer {
    overflow: hidden;
    padding: 30px 0 22px;
    margin-top: -16px;
  }
  .cookies-block {
    margin: 0 16px 20px;
    min-width: unset;
  }
  .footer-menu {
    flex-flow: column nowrap !important;
    a {
      margin-right: 0;
    }
  }
}

@media (max-width: $screen-xs) {
  .copyright-block {
    margin: 0 0 20px;
    // width: 100%;
  }
  //.footer-menu {
  //  flex-flow: column wrap;
  //  width: 100%;
  //}
}
