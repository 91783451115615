.food-ratio-wrap {
  .food-ratio-block {
    padding: 50px 0;
    @include title-main($mbd:36px, $mbm:10px, $fzd:38px, $fzm: 28px);
  }
  .weight-block {
    font-size: 18px;
  }
  .table-block {
    .title {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 24px;
    }
  }
  @media (max-width: $screen-sm) {
    .food-ratio-block {
      padding: 42px 0 30px;
    }
    .weight-block {
      font-size: 16px;
    }
  }
}
