@import "./core/colors";

.profile-page-wrap {
  .profile-top-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    .info-block {
      background-color: white;
      border-radius: 24px;
      padding: 16px;
      gap: 24px
    }
    .prop, .val {
      margin-bottom: 12px;
      font-size: 16px;
    }
    .prop {
      margin-right: 50px;
      color: #8F9399;
      min-width: 125px;
    }
    .edit-link {
      //color: $black;
      //@include link-line($black);
    }
    @media (max-width: $screen-sm) {
      padding: 12px;
      border-radius: 16px;
      .info-block {
        border-radius: 12px;
        padding: 8px;
        gap: 0px
      }
      .prop, .val {
        margin-bottom: 8px;
        font-size: 12px;
      }
      .prop {
        margin-right: 30px;
      }
      .val {
        font-weight: 500;
      }
    }
  }
  .services-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    .table-block {
      gap: 12px;
      padding: 12px;
      border-radius: 24px;
      background-color: white;
    }
    .img {
      width: 24px;
      height: 24px;
    }
    .title-small {
      margin-right: 30px;
      font-size: 18px;
      font-weight: 700;
    }
    .table td {
      border-top: none;
      a {
        color: $black;
      }
      .btn {
        margin-right: 30px;
      }
    }
    @media (max-width: $screen-sm) {
      gap: 8px;
      padding: 12px;
      border-radius: 24px;
      .table-block {
        gap: 8px;
        padding: 8px;
        border-radius: 12px;
      }
      .table {
        tr {
          display: flex;
          flex-flow: column nowrap;
          //padding-bottom: 10px;
        }
        td {
          padding: 8px;
        }
      }
    }
  }
  .body-index-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    .info-wrap {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      border-radius: 24px;
      background-color: white;
    }
    .title-small {
      font-size: 24px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
    }
    .prop,.val {
      font-size: 18px;
    }
    .prop {
      margin-right: 60px;
      color: #979797;
    }
    .img-block {
      margin-right: 10px;
    }
    .text {
      //font-size: 18px;
    }
    @media(max-width: $screen-sm) {
      gap: 16px;
      padding: 12px;
      border-radius: 24px;
      .info-wrap {
        gap: 8px;
      }
      .title-small {
        font-size: 16px;
      }
      .text, .prop, .val {
        font-size: 12px;
      }
      .val {
        font-weight: 500;
      }
    }
  }
  .calories-calc-block {
    .result-block {
      padding: 12px;
      border-radius: 16px;
      background-color: white;
      color: black;
      .program-img {
        height: 48px;
        width: 48px;
      }
      .img {
        height: 24px;
        width: 24px;
      }
    }
    .program-block {
      a {
      }
    }
    .prop,
    .val {
      font-size: 18px;
    }
    .prop {
      flex-grow: 1;
    }
    @media (max-width: $screen-sm) {
      //padding: 30px 0 30px;
    }
  }
  .profile-menu-block {
    position: relative;
    padding: 24px;
    border-radius: 32px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    .weight-loss {
      font-size: 18px;
    }
    tr td:first-child {
      font-weight: 500;
    }
    .slide-control {
      color: $text-default;
      &:hover {
        text-decoration: none;
      }
      .text {
        font-weight: bold;
        font-size: 16px;
      }
      .icon {
        font-size: 20px;
        &.icon-arrow-left {
          margin-right: 12px;
        }
        &.icon-arrow-right {
          margin-left: 12px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 12px;
      border-radius: 16px;
      .table-block {
        padding-bottom: 8px;
      }
      .right-block {
        position: absolute;
        justify-content: center;
        bottom: 16px;
        width: 100%;
        left: 0;
        z-index: 1;
      }
    }
  }
  .profile-block-notes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    .slide-control {
      color: $text-default;
      &:hover {
        text-decoration: none;
      }
      .text {
        font-weight: bold;
        font-size: 16px;
      }
      .icon {
        font-size: 20px;
        &.icon-arrow-left {
          margin-right: 12px;
        }
        &.icon-arrow-right {
          margin-left: 12px;
        }
      }
    }
    .notes-list {
      //padding-top: 10px;
      //margin-left: -38px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      .icon {
        display: none;
      }
      &.edit {
        .icon {
          display: block;
        }
      }
    }
    .notes-block {
      position: relative;
      padding: 12px;
      border-radius: 16px;
      //margin-left: 16px;
      //width: calc(25% - 16px);
      background-color: $white;
      max-width: 25%;
      .date {
        font-size: 14px;
        color: #979797;
      }
      .title {
        font-size: 16px;
      }
      .icon {
        position: absolute;
        color: $text-default;
        &:hover {
          text-decoration: none;
        }
      }
      .icon-clear {
        top: -8px;
        right: -8px;
        font-size: 18px;
      }
      .icon-edit {
        font-size: 23px;
        right: 5px;
        bottom: 5px;
      }
      .delete {
        height: 24px;
        width: 40px;
      }
      &.add-new {
        color: $white;
        background-color: $text-default;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon-add {
          margin: 4px 12px 0 0;
          font-size: 24px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 12px;
      border-radius: 16px;
      .notes-list {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
      }
      .notes-block {
        width: 100%;
        max-width: unset;
        .icon-clear {
          top: 8px;
          right: 8px;
        }
        .delete {
          width: 24px;
        }
      }
      .control-block {
        justify-content: center;
      }
    }
  }
  .events-results-block {
    padding: 60px 0;
    @include title-main($mbd:30px, $mbm:0, $fzd:38px, $fzm: 28px);
    .results-list {
      margin-left: -18px;
    }
    .result-block {
      margin-left: 18px;
      width: calc(25% - 18px);
      padding: 15px 10px;
      background: $white;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      .icon {
        margin-right: 10px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        font-size: 22px;
        background-color: #56CCF2;
        border-radius: 50%;
      }
      .link-more {
        color: $text-default;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 40px 0 20px;
      .results-list {
        margin-left: 0;
      }
      .result-block {
        margin-left: 0;
        margin-bottom: 18px;
        width: 100%;
        .desc {
          font-size: 14px;
        }
        .link-more {
          font-size: 14px;
        }
      }
    }
  }
  .checklist-block-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    .text-small {
      color: $gray7;
    }
    .tab-content-list {
      .tab-content {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .text-description {
      font-size: 18px;
      line-height: 25px;
      color: $color-tele2-text-gray;
      margin-bottom: 2em;
    }
    .tab-list {
      margin-bottom: 16px;
      display: flex;
      .tab {
        padding: 8px 12px;
        border-radius: 12px;
        flex-grow: 0;
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        &.active {
          background-color: $blue3;
          color: white;
          &:after {
            display: none;
          }
        }
      }
      &.gray {
        padding: 30px 15px;
        border-bottom: none;
        background-color: #F0F1F2;
        .tab {
          flex-grow: 0;
          padding: 0;
          margin-right: 37px;
          color: #8F9399;
          &.active {
            color: $text-default;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .result-list {
      margin-left: -16px;
    }
    .result-block {
      margin-left: 16px;
      max-width: 33%;
      padding: 12px;
      border-radius: 16px;
      background-color: $white;
      //box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
      .img-reaction {
        width: 24px;
        height: 24px;
      }
      .top-block {
        margin-bottom: 8px;
        .icon {
          margin-right: 10px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          font-size: 26px;
          color: $white;
          border-radius: 50%;
          background-color: $text-default;
          &.icon-cigar {
            &:before {
              margin: -4px 0 0 -3px;
              font-size: 22px;
            }
          }
        }
        .text {
          font-size: 14px;
        }
      }
      .list {
        margin-left: -7px;
        //margin-bottom: 18px;
        display: flex;
        flex-flow: row wrap;
        .icon {
          margin: 0 0 9px 9px;
          width: 32px;
          height: 32px;
          font-size: 22px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          color: rgba(31, 34, 41, 0.2);
          border-radius: 50%;
          background-color: $gray;
          &.now {
            color: $text-default;
          }
          &.done {
            color: #41A674;
            background: rgba(65, 166, 116, 0.3);
          }
          &.undone {
            color: #CC2128;
            background-color: rgba(204, 33, 40, 0.3);
          }
          &.digit {
            font-family: 'Standard Stencil Bold', sans-serif;
            font-size: 26px;
          }
        }
        &.small {
          margin-bottom: 7px;
          .icon {
            margin: 0 0 5px 3px;
            width: 23px;
            height: 23px;
            font-size: 13px;
          }
        }
      }
      .link-block {
        font-size: 16px;
        a {
          margin: 0 8px 5px 0;
          display: inline-block;
          color: $black;
          text-decoration: underline;
          &:hover,
          &.active {
            color: $blue;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      .btn {
        padding: 9px 20px;
        &:first-child {
          margin-right: 30px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 12px;
      border-radius: 16px;
      .result-list {
        margin-left: 0;
      }
      .tab-list {
        margin-bottom: 8px;
      }
      .result-block {
        margin-left: 0;
        max-width: inherit;
        .btn {
          &:first-child {
            margin-right: 20px;
          }
        }
        .top-block {
          .icon {
            margin-right: 8px;
            width: 32px;
            height: 32px;
            font-size: 22px;
          }
        }
        .list {
          .icon {
            margin-left: 7px;
            font-size: 19px;
            width: 27px;
            height: 27px;
            &.digit {

            }
          }
        }
      }
    }
  }
}
