.player-container{
  @include aspect-ratio(16, 9, 'player-inner');
  background-color: #8c4e4e;
  .preview-block{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    video {
      width: 100%;
      height: 100%;
      background: #000;
    }
  }
  .stream-status{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: .875em;
    color: $white;
    text-transform: uppercase;
    z-index: 1;
    &:before{
      content: "";
      margin-right: 4px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: $errorText;
    }
  }
  .player-controls-top{
    position: absolute;
    top: 0;
    left: 0;
    right: inherit;
    width: 100%;
    height: 40px;
    color: $white;
    background: linear-gradient(to top, rgba(27,28,32, 0) 0%, rgba(27,28,32, 0.7) 100%);
    opacity: 1;
    transition: opacity 250ms ease;
    z-index: 1;
    .mobile{
      display: none;
    }
    .viewers-count{
      position: absolute;
      top: 10px;
      right: 40px;
      font-size: 10px;
    }
    .icon{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 17px;
      color: $white;
      text-decoration: none;
      z-index: 1;
    }
    &.fade{
      opacity:0;
    }
  }
  .player-controls-bot{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 250ms ease;
    z-index: 1;
    .bg-block{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(0deg,rgba(0,0,0,.8) 0,rgba(0,0,0,.35) 60%,transparent);
    }
    .control{
      cursor: pointer;
      .icon{
        display: block;
        color: $blue;
        &:hover{
          color: $white;
        }
      }
      &.play{
        position: absolute;
        left: 23px;
        bottom: 15px;
        .icon{
          font-size: 56px;
        }
      }
      &.volume, &.timer, &.right-block{
        position: absolute;
        bottom: 28px;
        .icon{
          font-size: 29px;
        }
      }
      &.volume{
        width: 80px;
        left: 114px;
        bottom: 26px;
        .on{
          position: relative;
          .scroll-block{
            position: absolute;
            top: 2px;
            right: 0;
            width: 47px;
            img{
              width: 100%;
            }
            .scroll{
              position: absolute;
              right: -5px;
              z-index: 2;
              opacity: 0;
              width: 57px;
              height: 25px;
            }
            .scroll-fake{
              position: absolute;
              width: 100%;
              overflow: hidden;
              z-index: 1;
            }
          }
        }
      }
      &.timer{
        color: $white;
        right: 147px;
        bottom: 32px;
        cursor: default;
      }
      &.right-block {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        right: 20px;
        .control {
          position: relative;
          margin-left: 30px;
        }
      }
      .settings{
        .quality-list{
          display: none;
          position: absolute;
          left: 50%;
          bottom: 42px;
          padding: 6px 0;
          background-color: $white;
          border-radius: 5px;
          transform: translate(-40%, 0);
          z-index: 2;
          &:after {
            content: '';
            position: absolute;
            left: 23px;
            bottom: -4px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 5px 0 5px;
            border-color: $white transparent transparent transparent;
          }
          .radio{
            padding-bottom: 0;
            margin-bottom: 0;
            &:last-child{
              margin-bottom: 0;
            }
            input[type="radio"] {
              & + label {
                &::before, &::after {
                  background: $bgDark;
                }
                &::before {
                  border: 2px solid $textGray;
                }
                &::after {
                  background-color: $blue;
                }
                &:hover:before {
                  border-color: $blue;
                }
              }
              &:checked {
                & + label {
                  background-color: $blue;
                }
              }
            }
            label {
              padding: 0 12px;
              line-height: 24px;
              width: 70px;
              font-size: 14px;
              color: $black;
              &:hover {
                background-color: $blue;
              }
              &:before,&:after {
                display: none;
              }
            }
          }
        }
        .icon{
          font-size: 32px;
        }
      }
      .resize{
        .icon{
          font-size: 33px;
        }
      }
      img{
        position: relative;
        display: block;
      }
      .on{
        display: block;
      }
      .off{
        display: none;
      }
      &.active{
        .on{
          display: none;
        }
        .off{
          display: block;
        }
      }
    }
    .progress-bar{
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      bottom: 84px;
      cursor: pointer;
      background-color: $white;
      .progress-thumb{
        margin-left: -6px;
        position: absolute;
        top: -5px;
        left: 6px;
        width: 13px;
        height: 13px;
        background-color: $blue;
        border-radius: 50%;
        cursor: pointer;
      }
      .progress-played{
        position: absolute;
        left: 0;
        height: 100%;
        background-color: $blue;
      }
      .progress-buffer{
        position: absolute;
        left: 0;
        height: 100%;
        background-color: #b1faff;
      }
    }
    &.fade{
      opacity:0;
    }
  }

  .loading {
    position: absolute;
    color: #fff;
    bottom: 50%;
    left: 50%;
  }
}

@media (max-width: $screen-sm) {
  .player-container{
    .player-controls-top{
      .mobile{
        display: block;
      }
      .stream-status{
        top: 10px;
        left: 10px;
        right: inherit;
        font-size: 10px;
        &:before{
          margin-right: 5px;
          width: 6px;
          height: 6px;
        }
      }
    }
    .player-controls-bot{
      .progress-bar{
        bottom: 42px;
      }
      .control{
        .icon{
          color: $white;
        }
        &.timer{
          display: none;
          font-size: 14px;
          right: 50%;
          bottom: 12px;
          transform: translate(50%, 0);
        }
        &.play{
          left: 50%;
          top: 50%;
          height: 47px;
          transform: translate(-50%, -50%);
          .icon{
            font-size: 47px;
          }
        }
        &.volume{
          left: 0;
          bottom: 1px;
          .icon{
            padding: 10px;
            font-size: 18px;
          }
          .on{
            .scroll-block{
              display: none;
            }
          }
        }
        &.right-block{
          right: 0;
          bottom: 0;
          .icon{
            padding: 10px;
            font-size: 18px;
          }
          .control {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
