@import "../core/dimentions";
@import "../core/colors";
@import "../core/fonts";

.banner-compact {
  display: block;
  position: relative;

  margin-top: 40px;
  padding: 20px 35px;

  &:hover {
    text-decoration: none;
  }

  & > .title {
    @include font-tele2-sub-title();
    color: $color-tele2-text-black;
  }

  & > .content {
    @include font-tele2-medium();
    color: $color-tele2-text-black;
    margin-top: 11px;

    & > .bold {
      font-weight: bold;
    }
  }

  &:before, &:after {
    display: block;
    content: "";

    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: -1;
  }

  &:before {
    background-color: $color-tele2-background-blue;
  }

  @media (max-width: $mobile) {
    padding: 20px 35px 20px $dim-page-padding-mobile;
    margin-top: 0;
    margin-left: -$dim-page-padding-mobile;
    margin-right: -$dim-page-padding-mobile;
  }
}