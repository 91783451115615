.food-page-wrap {
  .table-block {
    overflow-x: auto;
    @include smooth-scroll();
    @include scrollbar(auto, 7px, #DADADA, $blue);
    .title {
      margin-bottom: 10px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 34px;
    }
    .link {
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      color: $black;
      @include link-line(#B2B2B2);
      display: none;
    }
    .table {
      tbody {
        tr {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .product-description-block {
    display: flex;
    .col {
      padding: 0;
      flex-grow: 1;
    }
    .title {
      margin-bottom: 28px;
      font-family: 'T2 Halvar Breitschrift', sans-serif;
      font-weight: bold;
      font-size: 24px;
      color: $text-default;
    }
    .text {
      font-size: 18px;
      color: #626469;
    }
    .separator {
      margin: 0 20px;
      width: 1px;
      background-color: rgba(31, 34, 41, 0.4);;
    }
  }
  .widget-form-block {
    .col-block {
      width: 34.5%;
    }
    .custom-select-block,
    .custom-input-block {
      width: 100%;
      min-width: inherit;
    }
    .custom-select-block,
    .btn-block {
      margin-bottom: 15px;
    }
    .add-more {
      margin: 20px 0 10px;
    }
  }
  &.food-days {
    .widget-box {
      &:nth-child(odd) {
        background-color: $gray;
      }
    }
  }
}

.calories-calculator {
  position: relative;
  &.calc-img {
    &:after {
      content: '';
      position: absolute;
      margin-top: -245px;
      right: 0;
      top: 50%;
      display: block;
      width: 720px;
      height: 490px;
      filter: grayscale(100%);
      background: url('../img/slider1.png') right center no-repeat;
    }
  }
  // filter: grayscale(100%);
  .text {
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 25px;
    color: #626469;
  }
  .title-small {
    margin-bottom: 30px;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }
  .text-field {
    width: 100%;
    max-width: 350px;
  }
  .gender {
    .radio:first-child {
      margin-right: 82px;
    }
  }
  .checkbox,
  .radio {
    label {
      color: $text-default;
    }
  }
  .goal {
    .checkbox {
      padding-bottom: 0;
    }
  }
  label {
    color: $gray2;
  }
  .phys {
    margin-bottom: 0;
    .radio {
      padding-bottom: 15px;
      &:last-child {
        padding-bottom: 0;
        label {
          margin-bottom: 0;
        }
      }
    }
  }
  .output-block {
    margin: 30px 0 60px;
    max-width: 545px;
    padding: 20px 16px;
    border: 1px solid #E8E8ED;
    .form-wrap {
      max-width: 545px;
    }
    .form-group {
      max-width: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .radio {
      padding-bottom: 0;
      margin-bottom: 10px;
      label {
        margin-bottom: 0;
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .stats-list {
    margin-bottom: 35px;
    font-size: 18px;
    .element {
      max-width: 735px;
      margin-bottom: 15px;
    }
    .stat {
      margin: 0 15px 6px 0;
      flex-grow: 1;
      color: #979797
    }
    .data {
      color: $text-default
    }
  }
  form {
    padding: 0;
    background-color: transparent;
  }
  &.result {
    background-image: none;
  }

}

.food-product-block {
  .product-properties {
    display: flex;
    .product-img {
      margin-right: 12%;
      width: 39.8%;
      height: 360px;
      background-position: center;
      background-size: cover;
    }
    .properties-block {
      flex-grow: 1;
      .title-block {
        margin-bottom: 18px;
        max-width: 400px;
        .title {
          margin-right: 15px;
          font-family: 'T2 Halvar Breitschrift', sans-serif;
          font-size: 24px;
          line-height: 34px;
          font-weight: bold;
          color: $black;
        }
        .gram {
          color: #979797;
        }
      }
    }
  }
  .table-product {
    font-size: 16px;
    td {
      &:nth-child(1) {
        padding-right: 60px;
        color: #979797;
      }
      &:nth-child(2) {
        color: $text-default;
      }
    }
  }
}

.video-player-wrap {
  padding: 50px 0;
  background-color: $text-default;
  .player-block {
    flex-grow: 0;
    height: inherit;
    max-height: 625px;
    @include aspect-ratio(16, 9, 'player-container');
    background-color: $white;
  }
}

@media (max-width: 1024px) {
  .food-page-wrap {
    .calories-calculator {
      background: none;
    }
  }
}

@media (max-width: $screen-sm) {
  .food-page-wrap {
    .product-description-block {
      flex-flow: column wrap;
      .col {
        &:first-child {
          margin-bottom: 40px;
        }
      }
      .separator {
        display: none;
      }
    }
    .widget-form-block {
      .col-block {
        width: 100%;
      }
    }
    .video-player-wrap {
      padding: 0;
      .container-fluid {
        padding: 0;
      }
      .video-title {
        padding: 15px 10px 18px;
        color: $white;
        font-size: 14px;
      }
    }
  }
  .calories-calculator {
    .stats-list {
      font-size: 14px;
      line-height: 20px;
    }
    .output-block {
      flex-grow: 1;
    }
    .text {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: normal;
    }
    .title-small {
      margin-bottom: 20px;
    }
    .output-block {
      margin-bottom: 30px;
    }
    .inputs-block {
      margin-bottom: 20px;
    }
    .form-group.gender {
      .radio {
        padding-bottom: 0;
        label {
          margin-bottom: 0;
        }
      }
    }
    &:after {
      display: none;
    }
    &.calc-img {
      &:after {
        display: none;
      }
    }
  }
  .food-product-block {
    .product-properties {
      .product-img {
        margin-right: 32px;
        width: 285px;
        height: 360px;
        background-position: center;
        background-size: cover;
      }
      .properties-block {
        flex-grow: 1;
        .title-block {
          margin-bottom: 15px;
          max-width: 400px;
          .title {
            margin-bottom: 10px;
            font-family: 'T2 Halvar Breitschrift', sans-serif;
            font-size: 20px;
            line-height: normal;
            font-weight: bold;
            color: $black;
          }
          .gram {
            font-size: 16px;
            color: #979797;
          }
        }
      }
    }
    .table-product {
      font-size: 14px;
      td {
        &:nth-child(1) {
          padding-right: 60px;
          color: #979797;
        }
        &:nth-child(2) {
          color: $text-default;
        }
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .table {
    font-size: 12px;
  }
  .food-product-block {
    .product-properties {
      flex-flow: row wrap;
      .product-img {
        margin: 0 0 20px;
        width: 100%;
        height: 210px;
      }
    }
  }
  .food-page-wrap {
    .product-description-block {
      .title {
        //margin-bottom: 10px;
        font-size: 22px;
        font-weight: bold;
      }
      .text {
        font-size: 12px;
      }
    }
  }
}
