header {
  background-color: black;
  .menu-wrap {
    max-width: 1136px;
    padding: 12px;
    margin: 0 auto;
    background-color: black;
    .logo {
      display: block;
      width: 56px;
      img {
        //display: block;
        //width: 100%;
      }
    }
    .menu {
      a {
        color: $white;
        text-decoration: none;
        font-size: 12px;
        padding: 8px;
        gap: 8px;
      }
      .btn {
        font-size: 12px;
      }

    }
  }
  .nav-block {
    cursor: pointer;
    .icon {
      margin-right: 6px;
      flex-shrink: 0;
      font-size: 24px;
    }
    .text {
      font-size: 18px;
    }
  }
  .user-block {
    width: 32px;
    height: 32px;
    background: url(/static/img/svg/user-w.svg) 50% / cover no-repeat;
  }
}

@media (max-width: $screen-sm) {
  header {
    .menu-wrap {
      padding: 8px;
      .logo img {
        width: 40px;
      }
    }
  }
}
