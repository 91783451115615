@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?ipa4ei2');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?ipa4ei2#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/fonts/icomoon.ttf?ipa4ei2') format('truetype'),
  url('../fonts/icomoon/fonts/icomoon.woff?ipa4ei2') format('woff'),
  url('../fonts/icomoon/fonts/icomoon.svg?ipa4ei2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-size: 14px;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-diet:before {
  content: "\e91f";
}
.icon-recipe-book:before {
  content: "\e920";
}
.icon-fitness-male:before {
  content: "\e921";
}
.icon-first-aid-kit:before {
  content: "\e923";
}
.icon-tape:before {
  content: "\e924";
}
.icon-vitamines:before {
  content: "\e926";
}
.icon-fitness-female:before {
  content: "\e927";
}
.icon-yoga:before {
  content: "\e928";
}
.icon-grocery:before {
  content: "\e929";
}
.icon-massage:before {
  content: "\e92a";
}
.icon-writer:before {
  content: "\e92b";
}
.icon-healthy-lifestyle:before {
  content: "\e91a";
}
.icon-glass-of-water:before {
  content: "\e91b";
}
.icon-exercise:before {
  content: "\e91c";
}
.icon-beer:before {
  content: "\e90b";
}
.icon-cigar:before {
  content: "\e90c";
}
.icon-cake-pop:before {
  content: "\e90d";
}
.icon-pretzel:before {
  content: "\e90e";
}
.icon-soda:before {
  content: "\e90f";
}
.icon-coffee:before {
  content: "\e910";
}
.icon-energy-drink:before {
  content: "\e912";
}
.icon-touchscreen:before {
  content: "\e916";
}
.icon-Group-110:before {
  content: "\e90a";
  color: #00c864;
}
.icon-expand:before {
  content: "\e902";
}
.icon-minimize:before {
  content: "\e911";
}
.icon-mute:before {
  content: "\e913";
}
.icon-pause:before {
  content: "\e914";
}
.icon-play:before {
  content: "\e915";
}
.icon-resolution:before {
  content: "\e917";
}
.icon-sound:before {
  content: "\e91d";
}
.icon-views:before {
  content: "\e922";
}
.icon-search:before {
  content: "\e92c";
}
.icon-nav:before {
  content: "\e91e";
}
.icon-close:before {
  content: "\e900";
}
.icon-menu:before {
  content: "\e925";
}
.icon-enter:before {
  content: "\e940";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-telegram:before {
  content: "\e918";
  color: #34caff;
}
.icon-instagram:before {
  content: "\e919";
  color: #d24b91;
}
.icon-youtube-with-circle:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-cheveron-up:before {
  content: "\e903";
}
.icon-cheveron-right1:before {
  content: "\e904";
}
.icon-cheveron-left:before {
  content: "\e905";
}
.icon-cheveron-down:before {
  content: "\e906";
}
.icon-checkmark:before {
  content: "\e93c";
}
.icon-cinema-mode:before {
  content: "\e941";
}
.icon-info:before {
  content: "\e92d";
}
.icon-edit:before {
  content: "\e92e";
}
.icon-clear:before {
  content: "\e92f";
}
.icon-add:before {
  content: "\e930";
}
.icon-butterfly-net:before {
  content: "\e931";
}
.icon-money-bag:before {
  content: "\e932";
}
.icon-alarm:before {
  content: "\e933";
}
.icon-water:before {
  content: "\e934";
}
.icon-no-smoking:before {
  content: "\e935";
}
