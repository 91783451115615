.health-food-page-title-block {
  .top-border {
    margin-bottom: -25px;
    padding-bottom: 41px;
    padding: 16px 16px 49px 16px;
  }
  .promo-block {
    position: relative;
    height: 360px;
    background: url(/static/img/banners/promo-food.jpg) 100% no-repeat;
    border-radius: 32px;
    padding: 24px;
    background-size: contain;
    .info-block {
      height: 100%;
      max-width: 520px;
      padding: 24px;
      background-color: $green;
      color: black;
      border-radius: 24px;
      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 34px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }
      .text {
        font-size: 15px;
        line-height: 22px;
      }
    }

  }
  @media (max-width: $screen-sm) {
    .top-border {
      margin-bottom: -60px;
    }
    .promo-block {
      height: 398px;
      border-radius: 16px;
      padding: 0px;
      background: unset;
      .img {
        height: 204px;
        background-size: contain;
        border-radius: 16px;
      }
      .info-block {
        height: unset;
        padding: 12px;
        border-radius: 16px;
        gap: 8px;
        background-color: $gray3;
      }
    }
  }
}

.health-products-wrap {
  padding: 0 0 60px;
  @include title-main($mbd:34px, $mbm:20px, $fzd:38px, $fzm:28px);
  .list {
    margin-left: -36px;
    overflow-x: auto;
    @include smooth-scroll();
    scroll-snap-type: x proximity;
  }
  .product-block {
    margin-left: 36px;
    flex-shrink: 0;
    .img-block {
      margin-bottom: 10px;
      width: 155px;
      height: 225px;
      background-size: 100% 100%;
    }
    .title {
      font-size: 16px;
    }
  }
}

.slider-block-wrap {
  padding: 60px 0;
  @include title-main($mbd:34px, $mbm:20px, $fzd:38px, $fzm:28px);
  .slider-block {
    display: flex;
    height: 500px;
    background-color: $gray;
    .slide-content-wrapper {
      padding: 80px 30px 0;
    }
    .slider-content {
      overflow: hidden;
      flex-grow: 1;
      height: 100%;
    }
    .slide {
      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      height: 100%;
      display: none;
      &.active {
        display: block;
      }
      .img-block {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        img {
          margin: 0 0 0 auto;
          display: block;
        }
      }
      .info-block {
        z-index: 1;
        .title {
          margin-bottom: 130px;
          font-family: 'T2 Halvar Breitschrift', sans-serif;
          color: $black;
          font-weight: bold;
          font-size: 48px;
          line-height: 60px;
        }
      }
    }
    .btn {
      min-width: 200px;
    }
    .slider-control {
      padding: 20px 20px;
      max-width: 280px;
      flex-grow: 1;
      border-left: 2px solid rgba(31, 34, 41, 0.4);
      .control {
        position: relative;
        margin-bottom: 20px;
        display: block;
        font-size: 21px;
        line-height: 29px;
        color: rgba($text-default, .4);
        cursor: pointer;
        &:before {
          display: none;
          content: '';
          position: absolute;
          top: -4px;
          left: -22px;
          height: 40px;
          width: 2px;
          background-color: #191818;
        }
        &.active,
        &:hover {
          &:before {
            display: block;
          }
          color: $text-default;
        }
      }
      .more-link {
        font-size: 18px;
        text-decoration: none;
        color: rgba($text-default, .4);
        .icon {
          margin-left: 10px;
          font-size: 13px;
        }
        &:hover {
          color: $text-default;
        }
      }
    }
  }
  @media (max-width: $screen-sm) {
    padding: 0;
    .slider-block {
      .slide {
        .info-block {
          .title {
            font-size: 36px;
            line-height: 43px;
          }
        }
      }
      .slider-control {
        .control {
          font-size: 18px;
          line-height: 25px;
        }
        .more-link {
          white-space: normal;
        }
      }
    }
  }
  @media (max-width: $screen-xs) {
    .slider-block {
      flex-flow: column nowrap;
      height: auto;
      .slide {
        padding: 30px 10px 0;
        .img-block {
          position: relative;
          img {
            margin: 0 auto;
            max-width: 100%;
          }
        }
        .info-block {
          padding-bottom: 30px;
          .title {
            margin-bottom: 20px;
            font-size: 28px;
            line-height: 33px;
          }
        }
      }
      .slider-control {
        padding: 15px 5px;
        max-width: inherit;
        border-top: 1px solid rgba(31, 34, 41, 0.2);
        border-left: none;
        .control-menu {
          display: flex;
          flex-flow: row wrap;
          .control {
            margin: 0 35px 10px 0;
            font-size: 14px;
            line-height: 20px;
            &.active {
              &:before {
                display: none;
              }
            }
          }
          .more-link {
            flex-grow: 1;
            justify-content: space-between;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.generic-list-block {
  padding: 70px 0 60px;
  @include title-main($mbd:36px, $mbm:20px, $fzd:38px, $fzm:28px);
  .more-link {
    color: $text-default;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .list {
    margin-left: -36px;
    margin-bottom: 64px;
    overflow-x: auto;
    @include smooth-scroll();
    scroll-snap-type: x proximity;
  }
  .list-block {
    margin-left: 36px;
    flex-shrink: 0;
    width: calc(33.3% - 36px);
    .img-block {
      margin-bottom: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include aspect-ratio(16,9,'inner-wrap');
    }
    .title {
      font-size: 16px;
      color: $text-default;
    }
  }
  @media (max-width: $screen-sm) {
    .list {
      margin-left: -20px;
      margin-bottom: 30px;
    }
    .list-block {
      margin-left: 20px;
      width: 70%;
      .img-block {
        @include aspect-ratio(1,1,'inner-wrap');
      }
      .title {
        font-size: 14px;
      }
    }
  }
}
.shape-articles-block {
  &.black {
    gap: 8px;
    .tab-list {
      gap: 8px;
      .tab {
        background-color: $gray5;
        &.active {
          color: white;
          background-color: $blue4;
        }
      }
    }
    .tab-content-list {
      background-color: black;
      margin: 0 -24px;
      border-radius: 32px;
      padding: 24px;
      .title {
        color: white;
        padding-bottom: 16px;
      }
      .article-block .sub-title {
        background-color: $pink;
        color: black;
      }
    }
    @media (max-width: $screen-sm) {
      .tab-content-list {
        background-color: black;
        margin: 0 -12px;
        border-radius: 16px;
        padding: 12px;
        .title {
          padding-bottom: 8px;
        }
        .article-block .sub-title {
          background-color: $pink;
          color: black;
        }
      }
    }
  }
}
.drink-water-service {
  .drink-water {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 32px;
    background-color: $gray3;
    gap: 8px;

    .text {
      color: $gray6;
    }

    .btn-blue {
      border-color: $blue2;
      background-color: $blue2;
      color: black;
      min-width: 398px;
    }
  }
  .img {
    border-radius: 32px;
    background: url(/static/img/banners/wate-service.jpg) no-repeat;
    width: 690px;
    background-size: cover;
  }
}
