@use "sass:math";

@mixin text-overflow($width: inherit) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex($flow: nowrap, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-flow: row $flow;
  justify-content: $justify;
  align-items: $align;
}

@mixin aspect-ratio($width, $height, $name) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
  > .#{$name} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin position-centered() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin color-and-hover($color: $text-default, $colorHover: $black) {
  color: $color;
  &:hover {
    color: $colorHover;
  }
}

@mixin smooth-scroll() {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@mixin link-line($color: #B2B2B2){
  display: inline-block;
  text-decoration: none;
  &:after{
    content:'';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $text-default;
  }
  &:hover{
    &:after{
      background-color: transparent;
    }
  }
}

@mixin all-cover-link() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin placeholder($color: #000){
  &::-webkit-input-placeholder{
    color: $color;
  }
  &::-moz-placeholder{
    color: $color;
  }
  &:-ms-input-placeholder{
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin scrollbar($width: auto, $height: 5px, $color1: #D8D8D8, $color2: $blue) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }
  &::-webkit-scrollbar-track {
    background: $color1;
  }
  &::-webkit-scrollbar-thumb {
    background: $color2;
  }
}

@mixin hideScroll($show:none) {
  scrollbar-width: $show; /* Firefox */
  -ms-overflow-style: $show;  /* IE 10+ */
  &::-webkit-scrollbar {
    display: $show;
  }
}

@mixin title-main($mbd:36px, $mbm: 20px, $fzd:38px, $fzm:28px, $color:$text-default, $fwd:700, $fwm:700) {
  .title-main {
    margin-bottom: $mbd;
    font-size: $fzd;
    font-family: 'T2 Halvar Breitschrift', sans-serif;
    font-weight: $fwd;
    color: $color;
    @media (max-width: $screen-sm) {
      margin-bottom: $mbm;
      font-size: $fzm;
      font-weight: $fwm;
    }
  }
}

@mixin control-block($top: -64px) {
  position: absolute;
  top: $top;
  right: 0;
  width: 220px;
  @media (max-width: $screen-sm) {
    position: static;
    top: auto;
    right: auto;
    width: auto;
  }
}
