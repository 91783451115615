@font-face {
  font-family: 'ALSRublRegular';
  src: url('../fonts/rouble/rouble-webfont.eot');
  src: url('../fonts/rouble/rouble-webfont.eot?iefix') format('eot'),
  url('../fonts/rouble/rouble-webfont.woff') format('woff'),
  url('../fonts/rouble/rouble-webfont.ttf') format('truetype'),
  url('../fonts/rouble/rouble-webfont.svg#webfont0gpv2fp2') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'T2 Rooftop';
  src: url('../fonts/T2 Rooftop/T2_Rooftop-Regular.otf') format('opentype'),
       url('../fonts/T2 Rooftop/T2_Rooftop-Regular.ttf') format('truetype'),
       url('../fonts/T2 Rooftop/T2_Rooftop-Regular.woff2') format('woff2'),
       url('../fonts/T2 Rooftop/T2_Rooftop-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'T2 Halvar Breitschrift';
  src: url('../fonts/T2 Halvar Breitschrift addon/Webfonts/T2HalvarBreit-ExtraBold.eot');
  src: url('../fonts/T2 Halvar Breitschrift addon/Desktop OTF/T2HalvarBreit-ExtraBold.otf') format('opentype'),
       url('../fonts/T2 Halvar Breitschrift addon/Desktop TTF/T2HalvarBreit-ExtraBold.ttf') format('truetype'),
       url('../fonts/T2 Halvar Breitschrift addon/Webfonts/T2HalvarBreit-ExtraBold.svg') format('svg'),
       url('../fonts/T2 Halvar Breitschrift addon/Webfonts/T2HalvarBreit-ExtraBold.woff2') format('woff2'),
       url('../fonts/T2 Halvar Breitschrift addon/Webfonts/T2HalvarBreit-ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
