@import "../core/dimentions";

.promo-grid-column {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 1em;

  @media (max-width: $mobile) {
    display: flex;
    flex-flow: column;
  }
}